<template>
  <v-container fluid>
    <Snackbar :alert="alert" :msg="msg" :color="color"></Snackbar>
    <h1>Quote management</h1>
    <v-row>
      <v-col class="text-right">
        <v-btn class="my-3 ml-auto success" @click="addQuoteModal = true">
          <v-icon left> mdi-text-box-plus-outline </v-icon>
          Add
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      dense
      class="elevation-2 pt-2"
      :items="quotes"
      :search="search"
      :headers="headers"
      :footer-props="{ 'items-per-page-options': [10, 20, 30] }"
      :items-per-page="20"
    >
      <template v-slot:top>
        <v-text-field v-model="search" label="Search for any value" class="mx-4"></v-text-field>
      </template>

      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(item, index) in items" :key="item.id_quote" class="overflow-y-auto" style="max-height: 300px">
            <td>{{ index + 1 }}</td>
            <td>{{ item.quote }}</td>
            <td>{{ item.author }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="error" class="pointer" v-bind="attrs" v-on="on" @click.stop="deleteQuote(item)">mdi-trash-can</v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <!-- ADD QUOTE DIALOG -->
    <v-dialog v-model="addQuoteModal" max-width="60%" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Add quote</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="addQuoteModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form>
          <v-container fill-height fluid>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="6">
                <v-row align="center" justify="center" class="my-5">
                  <v-col cols="12" sm="10">
                    <v-text-field v-model="quote.author" label="Author" type="text" dense tabindex="1"></v-text-field>
                    <v-text-field v-model="quote.text" label="Quote" type="text" dense tabindex="2"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn app class="success mx-2" :loading="loading" tabindex="90" @click.prevent="addQuote(quote)"> Add </v-btn>
                    <v-btn app class="secondary" tabindex="99" @click="addQuoteModal = false">Cancel</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- REMOVE QUOTE DIALOG -->
    <v-dialog transition="dialog-top-transition" max-width="400" dense v-model="deleteQuoteModal">
      <template v-slot:default="deleteQuoteModal">
        <v-card>
          <v-toolbar color="error" dense dark>Confirmation</v-toolbar>
          <v-card-text dense>
            <div class="pt-15 text-center">
              Do you really want to delete this quote? <br />
              <i>"{{ selectedQuote.quote }}"</i>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text color="error" @click="deleteQuoteConfirm()">Confirm</v-btn>
            <v-btn text color="muted" @click="deleteQuoteModal.value = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import { loading } from '../../mixins/index';
import { formatHeaders } from '../../helpers/data-tables';
import PublicService from '../public.service';
import AdminService from './admin.service';

export default {
  name: 'AdminQuotes',
  mixins: [loading.default],
  components: {},
  data: () => ({
    search: null,
    quotes: [],
    addQuoteModal: false,
    deleteQuoteModal: false,
    selectedQuote: null,
    quote: {
      author: null,
      text: null
    }
  }),
  methods: {
    getQuotes() {
      PublicService.getQuotes().then(
        result => {
          this.quotes = result.data;
        },
        error => {
          console.log(error);
        }
      );
    },
    deleteQuote(quote) {
      this.deleteQuoteModal = true;
      this.selectedQuote = quote;
    },
    deleteQuoteConfirm() {
      this.triggerLoading();

      if (!this.selectedQuote) {
        this.setAlertColor('error');
        this.setMessage('No quote selected');
        this.disableLoading();
        this.triggerAlert();
        return;
      }

      AdminService.deleteQuote(this.selectedQuote.id_quote)
        .then(() => {
          this.disableLoading();
          this.deleteQuoteModal = false;
          this.getQuotes();
          this.selectedQuote = {};
        })
        .catch(error => {
          this.setAlertColor('error');
          this.setMessage(error?.response?.data?.error?.message || error?.response?.data?.error || 'Something went wrong');
          this.disableLoading();
          this.triggerAlert();
        });
    },
    addQuote(quote) {
      this.triggerLoading();

      if (!quote.author || !quote.text) {
        this.setAlertColor('error');
        this.setMessage('Please fill in the author and quote');
        this.disableLoading();
        this.triggerAlert();
        return;
      }

      AdminService.addQuote(quote)
        .then(() => {
          this.quote.author = null;
          this.quote.text = null;
          this.disableLoading();
          this.addQuoteModal = false;
          this.getQuotes();
        })
        .catch(error => {
          this.setAlertColor('error');
          this.setMessage(error?.response?.data?.error?.message || error?.response?.data?.error || 'Something went wrong');
          this.disableLoading();
          this.triggerAlert();
        });
    }
  },
  created() {
    this.getQuotes();
  },
  computed: {
    headers() {
      const addtlItems = [{ value: 'Action', text: 'Action', sortable: false, align: 'center' }];
      return formatHeaders(this.quotes, addtlItems);
    }
  }
};
</script>

<style lang="css" scoped></style>
