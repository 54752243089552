<template>
  <v-main>
    <v-container fill-height fluid>
      <Snackbar :alert="alert" :msg="msg" :color="color"></Snackbar>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4" lg="2">
          <v-img :lazy-src="logoImg" max-height="250" max-width="350" align="center" :src="logoImg" class="mb-8"></v-img>
          <v-form @submit.prevent="login">
            <v-text-field
              label="Username"
              name="username"
              v-model="username"
              type="text"
              filled
              dense
              :error="alert"
              background-color="white"
              style="height: 3.5em"
            ></v-text-field>
            <v-text-field
              label="Password"
              name="password"
              v-model="password"
              type="password"
              filled
              dense
              :error="alert"
              background-color="white"
              style="height: 4em"
            ></v-text-field>
            <v-btn x-large block dense color="primary" type="submit" :loading="loading">LOGIN</v-btn>
          </v-form>
          <p style="margin: 3em auto; text-align: center; color: #6c757d; font-size: 0.8em">... since 2015</p>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { loading } from './../../mixins/index';

export default {
  name: 'Login',
  mixins: [loading.default],
  data: () => ({
    username: null,
    password: null,
    logoImg: './../../imgs/zp_main.png'
  }),
  methods: {
    login() {
      this.triggerLoading();
      const payload = { username: this.username, password: this.password };

      if (this.username && this.password) {
        this.$store.dispatch('auth/login', payload).then(
          () => {
            this.$router.push({ name: 'admin-home' });
            this.disableLoading();
          },
          error => {
            console.log(error);
            this.setMessage(error?.response?.data?.error?.message || error?.response?.data?.error || 'Something went wrong');
            this.disableLoading();
            this.triggerAlert();
          }
        );
      } else {
        this.setAlertColor('error');
        this.setMessage('You have to fill in username and password');
        this.disableLoading();
        this.triggerAlert();
      }
    }
  }
};
</script>
