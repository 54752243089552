<template>
  <div>
    <div class="slideshow">
      <v-img v-for="photo in photos" :key="photo.id_photo" :src="photo.full_img_path" class="slideshow-image"></v-img>
    </div>
    <Header></Header>
  </div>
</template>

<script>
import Header from './shared/Header.vue';
import PublicService from './public.service';
export default {
  name: 'Home',
  components: {
    Header
  },
  data: () => ({
    serverHost: process.env.VUE_APP_API_BASE_URL,
    photos: [],
    length: 4
  }),
  created() {
    this.getHomePagePhotos();
  },
  computed: {},
  methods: {
    getHomePagePhotos() {
      PublicService.getHomePagePhotos().then(
        result => {
          const source = result?.data.map(r => ({ ...r, sort: Math.random() })).sort((a, b) => a.sort - b.sort);

          const picCount = 5;
          const max = source.length - picCount;
          const min = picCount;
          const rand = Math.round(Math.random() * (max - min) + min);

          this.photos = source.slice(rand, rand + picCount);
          this.photos.forEach(p => {
            p.full_img_path = this.serverHost + p.photo_path.replace('/uploads', '') + '/' + p.photo_name;
          });
        },
        error => {
          console.log(error);
        }
      );
    }
  }
};
</script>

<style lang="scss">
$items: 5;
$animation-time: 4s;
$transition-time: 0.5s;
$scale: 20%;

$total-time: ($animation-time * $items);
$scale-base-1: (1 + $scale / 100%);

.slideshow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slideshow-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background: no-repeat 50% 50%;
  background-size: cover;
  animation-name: kenburns;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: $total-time;
  opacity: 1;
  transform: scale($scale-base-1) translateX(500px);

  @for $i from 1 through $items {
    &:nth-child(#{$i}) {
      animation-name: kenburns-#{$i};
      z-index: ($items - $i);
    }
  }
}

@for $i from 1 through $items {
  @keyframes kenburns-#{$i} {
    $animation-time-percent: percentage($animation-time / $total-time);
    $transition-time-percent: percentage($transition-time / $total-time);

    $t1: ($animation-time-percent * ($i - 1) - $transition-time-percent / 2);
    $t2: ($animation-time-percent * ($i - 1) + $transition-time-percent / 2);
    @if ($t1 < 0%) {
      $t1: 0%;
    }
    @if ($t2 < 0%) {
      $t2: 0%;
    }

    $t3: ($animation-time-percent * ($i) - $transition-time-percent / 2);
    $t4: ($animation-time-percent * ($i) + $transition-time-percent / 2);
    @if ($t3 > 100%) {
      $t3: 100%;
    }
    @if ($t4 > 100%) {
      $t4: 100%;
    }

    $t5: (100% - $transition-time-percent / 2);
    $t6: (($t4 - $t1) * 100% / $t5);

    #{$t1} {
      opacity: 1;
      transform: scale($scale-base-1) translateX(($i * 72px % 200px)-100px);
    }
    #{$t2} {
      opacity: 1;
    }
    #{$t3} {
      opacity: 1;
    }
    #{$t4} {
      opacity: 0;
      transform: scale(1);
    }

    @if ($i != $items) {
      100% {
        opacity: 0;
        transform: scale($scale-base-1);
      }
    }

    @if ($i == 1) {
      $scale-plus: ($scale * (100% - $t5) / $t4);
      $scale-plus-base-1: (1 + ($scale + $scale-plus) / 100%);

      #{$t5} {
        opacity: 0;
        transform: scale($scale-plus-base-1);
      }
      100% {
        opacity: 1;
      }
    }
  }
}
</style>
