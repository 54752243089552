import axios from '../../axios/index';
import authHeader from './auth.header';

class AuthService {
  login(params) {
    return axios.post('/login', { data: params }).then(response => {
      if (response.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return response.data;
    });
  }
  logout() {
    localStorage.removeItem('user', { headers: authHeader() });
  }
}

export default new AuthService();
