const formatHeaders = (array, additionalItems = [], removeValues = []) => {
  const headers = [];

  // return empty if empty
  if (array.length == 0) return headers;

  Object.keys(array[0]).forEach(k => {
    const label = k.startsWith('id') ? '#' : titleCase(k.toUpperCase().replace(/_/g, ' '));
    headers.push({ value: k, text: label, sortable: true });
  });
  // add extra/action columns
  additionalItems.forEach(ai => {
    headers.push({
      value: ai.value,
      text: ai.text,
      sortable: ai.sortable
    });
  });
  return headers.filter(h => !removeValues.includes(h.value));
};

const titleCase = str => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

module.exports = {
  formatHeaders,
  titleCase
};
