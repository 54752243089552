<template>
  <v-container fluid>
    <Snackbar :alert="alert" :msg="msg" :color="color"></Snackbar>
    <h1>Settings</h1>
    <v-form @submit.prevent="changePassword">
      <v-container fill-height fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="6">
            <h4>Change password</h4>
            <v-divider></v-divider>
            <v-row align="center" justify="center" class="my-5">
              <v-col cols="12" sm="10">
                <v-text-field
                  v-model="current_password"
                  label="Current password"
                  :type="!passwordVisible ? 'password' : 'text'"
                  dense
                  :error="alert && !success"
                  tabindex="1"
                ></v-text-field>
                <v-text-field
                  v-model="new_password"
                  label="New password"
                  :type="!passwordVisible ? 'password' : 'text'"
                  dense
                  tabindex="2"
                  :error="alert && !success"
                  :append-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="showPassword"
                ></v-text-field>
                <v-text-field
                  v-model="new_password_confirm"
                  label="Confirm new password"
                  :type="!passwordVisible ? 'password' : 'text'"
                  tabindex="3"
                  :error="alert && !success"
                  dense
                ></v-text-field>
                <v-row>
                  <v-col class="text-right">
                    <v-btn app class="success" type="submit" :loading="loading" tabindex="4">Change</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import { loading } from '../../mixins/index';
import AdminService from './admin.service';

export default {
  name: 'AdminSettings',
  mixins: [loading.default],
  components: {},
  data: () => ({
    current_password: null,
    new_password: null,
    new_password_confirm: null,
    passwordVisible: false,
    success: true
  }),
  methods: {
    showPassword() {
      this.passwordVisible = !this.passwordVisible;
    },
    changePassword() {
      this.triggerLoading();

      // check if all fields are provided
      if (!this.current_password || !this.new_password || !this.new_password_confirm) {
        this.success = false;
        this.setAlertColor('error');
        this.setMessage('All fields are required');
        this.disableLoading();
        this.triggerAlert();
        return;
      }
      // check if newPassword is diff from the OldPassword
      if (this.current_password == this.new_password) {
        this.success = false;
        this.setAlertColor('error');
        this.setMessage('Current password and new password must differ');
        this.disableLoading();
        this.triggerAlert();
        return;
      }
      // check if new password and confirmed password match
      if (this.new_password != this.new_password_confirm) {
        this.success = false;
        this.setAlertColor('error');
        this.setMessage('New password does not match with the confirmed password');
        this.disableLoading();
        this.triggerAlert();
        return;
      }

      AdminService.changePassword({ new_password: this.new_password, current_password: this.current_password })
        .then(() => {
          this.success = true;
          this.current_password = null;
          this.new_password = null;
          this.new_password_confirm = null;
          this.disableLoading();
          this.setAlertColor('success');
          this.setMessage('Password changed');
          this.triggerAlert();
        })
        .catch(error => {
          this.success = false;
          this.setAlertColor('error');
          this.setMessage(error?.response?.data?.error?.message || error?.response?.data?.error || 'Something went wrong');
          this.disableLoading();
          this.triggerAlert();
        });
    }
  },
  created() {},
  computed: {}
};
</script>

<style lang="css" scoped></style>
