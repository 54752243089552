<template lang="html">
  <header>
    <v-container class="header" fill-height fluid>
      <v-row align="center" class="logo" justify="center">
        <router-link :to="{ name: 'home' }"> <img :src="getLogo" alt="Logo" /> </router-link>
      </v-row>
      <v-divider :class="activeColor"></v-divider>
      <v-row align="center" class="navigation mt-1" justify="center" :color="activeColor">
        <nav>
          <v-app-bar-nav-icon @click="drawer = true" class="d-flex d-sm-none" align="right"></v-app-bar-nav-icon>
          <v-tabs class="navigation__menu d-none d-sm-flex" background-color="transparent" :color="activeColor">
            <v-tabs-slider color="rgba(255, 0, 0, 0.0)"></v-tabs-slider>
            <v-tab
              v-for="(menu, index) in menuItems"
              :color="activeColor"
              :key="index"
              exact-active-class="active"
              class="navigation__menu_item"
              :class="homeClass"
              :to="{ name: menu.link }"
            >
              {{ menu.name }}
            </v-tab>
          </v-tabs>
        </nav>
        <v-navigation-drawer v-model="drawer" absolute temporary class="fullPageMenu">
          <v-list nav dense class="mt-3">
            <v-list-item-group>
              <template v-for="(menu, index) in menuItems">
                <router-link :to="{ name: menu.link }" class="no-decor" :key="index">
                  <v-list-item style="justify-content: center" class="py-0">
                    <v-list-item-title :to="{ name: menu.link }">{{ menu.name }}</v-list-item-title>
                  </v-list-item>
                </router-link>
              </template>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-row>
    </v-container>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data: () => ({
    transparentLogo: '/imgs/zp_main_transparent.png',
    regularLogo: '/imgs/zp_main.png',
    fontColorLight: '#FFFFFF',
    fontColorDark: '#666666',
    drawer: false,
    menuItems: [
      { link: 'home', name: 'Úvodní stránka' },
      { link: 'galerie', name: 'Fotogalerie' },
      { link: 'mapa', name: 'Mapa světa' },
      { link: 'burgers', name: 'Burger tour' }
    ]
  }),
  computed: {
    isHomePage() {
      return this.$router.currentRoute.path == '/' ? true : false;
    },
    getLogo() {
      return this.isHomePage ? this.transparentLogo : this.regularLogo;
    },
    homeClass() {
      return this.isHomePage ? 'home' : 'not-home';
    },
    activeColor() {
      return this.isHomePage ? 'white' : '#666666';
    }
  }
};
</script>

<style lang="css" scoped>
.header {
  position: relative;
  text-align: center;
  font-weight: 300;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0);
  padding: 1em 2em;
  line-height: 1.5;
  display: block !important;
}

.active {
  border-bottom: 1px solid #dbdbdb;
}

.header > * {
  display: flex;
  align-items: top;
}

.logo img {
  margin: 2.5em auto;
  min-height: 7vw;
  width: 20vw;
}
@media only screen and (max-width: 1600px) {
  .logo img {
    min-height: 5vw;
    width: 25vw;
  }
}
@media only screen and (max-width: 1263px) {
  .logo img {
    min-height: 3vw;
    width: 30vw;
  }
}
@media only screen and (max-width: 960px) {
  .logo img {
    min-height: 7vw;
    width: 40vw;
  }
}
@media only screen and (max-width: 599px) {
  .logo img {
    min-height: 7vw;
    width: 70vw;
  }
}

.v-tab {
  color: #666;
  font-weight: 600;
  font-size: 0.75rem;
}

.v-tab + a.not-home {
  color: #666 !important;
}

a.home {
  color: white !important;
}

/* custom full page menu */
.fullPageMenu {
  width: 100% !important;
  min-height: 30vh !important;
}
</style>
