<template>
  <v-container>
    <Header></Header>
    <v-container class="burger">
      <v-container class="burger_detail polaroid" fluid>
        <v-row>
          <v-col class="img">
            <img class="burger_detail_img pointer" :src="burger.full_img_path" @click="selectedImage = burger" />
            {{ burger.place }}
            <div class="location"><v-icon small class="mr-1">mdi-pin</v-icon>{{ burger.city }}, {{ burger.country_name }}</div>
          </v-col>
          <v-col>
            <v-container> {{ burger.description }}</v-container>
            <transition name="view" appear>
              <v-container class="rating__preview pt-6">
                <v-row dense>
                  <v-col class="label">Houska:</v-col>
                  <v-col cols="20" sm="18">
                    <v-rating
                      class="star"
                      v-model="burger.houska"
                      color="yellow darken-3"
                      background-color="#eee"
                      empty-icon="$ratingFull"
                      half-increments
                      hover
                      readonly
                    ></v-rating>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="label">Maso:</v-col>
                  <v-col cols="20" sm="18" xs="20">
                    <v-rating
                      class="star"
                      v-model="burger.maso"
                      color="yellow darken-3"
                      background-color="#eee"
                      empty-icon="$ratingFull"
                      half-increments
                      hover
                      readonly
                    ></v-rating>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="label">Obsluha:</v-col>
                  <v-col cols="20" sm="18">
                    <v-rating
                      class="star"
                      v-model="burger.obsluha"
                      color="yellow darken-3"
                      background-color="#eee"
                      empty-icon="$ratingFull"
                      half-increments
                      hover
                      readonly
                    ></v-rating>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="label">Prostředí:</v-col>
                  <v-col cols="20" sm="18">
                    <v-rating
                      class="star"
                      v-model="burger.prostredi"
                      color="yellow darken-3"
                      background-color="#eee"
                      empty-icon="$ratingFull"
                      half-increments
                      hover
                      readonly
                    ></v-rating>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="label">Hranolky:</v-col>
                  <v-col cols="20" sm="18">
                    <v-rating
                      class="star"
                      v-model="burger.hranolky"
                      color="yellow darken-3"
                      background-color="#eee"
                      empty-icon="$ratingFull"
                      half-increments
                      hover
                      readonly
                    ></v-rating>
                  </v-col>
                </v-row>
              </v-container>
            </transition>
          </v-col>
        </v-row>
      </v-container>
      <v-overlay v-if="selectedImage" opacity="0.9" @click="selectedImage = null">
        <v-img contain :src="selectedImage ? selectedImage.full_img_path : ''" class="burger_image_overlay"></v-img>
      </v-overlay>
      <ToTop></ToTop>
    </v-container>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from './shared/Header.vue';
import Footer from './shared/Footer.vue';
import ToTop from './shared/ToTop.vue';
import PublicService from './public.service';
import { keyboardHandler } from '../mixins/index';
import moment from 'moment';

export default {
  name: 'Burgers',
  mixins: [keyboardHandler.default],
  components: {
    Header,
    Footer,
    ToTop
  },
  data: () => ({
    serverHost: process.env.VUE_APP_API_BASE_URL,
    selectedImage: null,
    burger: null
  }),
  methods: {
    getBurgerDetails(slug) {
      PublicService.getBurgerDetails(slug).then(
        result => {
          moment.locale('cs');
          this.burger = result.data;
          this.burger.date_tasted = moment(this.burger.date_tasted).format('D. MMMM, YYYY');
          this.burger.full_img_path = this.serverHost + this.burger.picture_path.replace('/uploads', '') + '/' + this.burger.picture_name;
        },
        error => {
          // const err = error?.response?.data?.error?.message || error?.response?.data?.error || 'Something went wrong';
          const status = parseInt(error?.response?.status) || 404;
          const message = error?.response?.data?.error?.message || 'Something went wrong';
          if (status === 404) this.$router.push({ name: 'not-found', params: { message } });
        }
      );
    }
  },
  created() {
    this.$watch(
      () => this.$route.params,
      toParams => {
        this.getBurgerDetails(toParams.slug);
        this.$vuetify.goTo(0);
      }
    );
    this.getBurgerDetails(this.$route.params.slug);
    this.$vuetify.goTo(0);
  }
};
</script>

<style lang="css" scoped>
.burger_detail_img {
  width: 100%;
  flex-wrap: wrap;
}
.burger_detail {
  min-height: 40vh;
  width: 80%;
}
.rating__preview {
  margin: 0 auto;
  width: 100%;
  display: grid;
  justify-content: center;
  font-size: 0.5rem;
}
.label {
  font-weight: 600;
  font-size: 1.1rem;
  margin-top: 7px;
}
.rating {
  color: rgb(224, 220, 3);
  bottom: 0.2rem;
  text-align: left;
  justify-items: left;
  align-items: left;
  justify-content: flex-start;
  align-items: flex-start;
}

.burger_image_overlay {
  width: 95vw;
  height: 95vh;
}

.location {
  text-align: right;
  font-size: 1.2rem;
  font-family: 'Reenie Beanie', cursive;
}
</style>
