<template>
  <v-container fluid>
    <Header></Header>
    <v-container fluid>
      <div class="text-center">
        Připravili jsme pro Vás mapu světa s vyznačenými zeměmi, které jsme společně navštívili. Po kliknutí na danou zemi, se Vám zobrazí všechny naše zážitky,
        které jsme tam zažili 🙂
      </div>
      <!-- <v-container class="mt-10" v-if="1 == 2">
        <world-map :mapOptions="mapOptions" v-on:map-clicked="onMapClick"></world-map>
      </v-container> -->
      <v-container>
        <world-map-d3 :mapOptions="mapOptions"></world-map-d3>
      </v-container>
      <ToTop></ToTop>
    </v-container>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from './shared/Header.vue';
import Footer from './shared/Footer.vue';
// import WorldMap from './shared/Map.vue';
import WorldMapD3 from './shared/MapD3.vue';
import ToTop from './shared/ToTop.vue';
import PublicService from './public.service';

export default {
  name: 'Mapa',
  components: {
    Header,
    Footer,
    // WorldMap,
    ToTop,
    WorldMapD3
  },
  data: () => ({
    mapOptions: {
      selectedCountries: [],
      countryList: [],
      borderWidth: 1
    }
  }),
  methods: {
    getCountryStats() {
      PublicService.getCountryStats().then(
        result => {
          this.mapOptions.countryList = result.data.countryStats;
          this.mapOptions.selectedCountries = this.mapOptions.countryList.map(r => r.country_code);
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  created() {
    console.log('Loading states');
    this.getCountryStats();
  }
};
</script>

<style></style>
