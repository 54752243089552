<template>
  <v-container fluid>
    <Snackbar :alert="alert" :msg="msg" :color="color"></Snackbar>
    <h1>Manage visited countries</h1>
    <v-container fill-height fluid>
      <v-row fluid justify="center" align="center">
        <v-col class="d-flex" style="flex-direction: row" cols="12" sm="8">
          <v-autocomplete
            v-model="selectedCountry"
            clearable
            :items="availableCountries"
            label="Add country"
            item-text="name"
            item-value="id_country"
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex" style="flex-direction: row" cols="12" sm="2">
          <v-btn @click="addSelectedCountry()"> <v-icon left> mdi-plus </v-icon> Add</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container style="width: 50%">
      <v-row class="mx-10" justify="center" align="center">
        <v-chip
          v-for="country in visitedCountries"
          :key="country.id_country"
          class="ma-1 inline"
          close
          color="primary"
          @click:close="removeCountry(country.id_country)"
        >
          <span class="font-weight-bold spaced-letters">{{ country.name }}</span>
        </v-chip>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col col="12" class="text-right">
          <v-btn color="success" :loading="loading" @click="updateCountries()">Save</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { loading } from './../../mixins/index';
import AdminService from './admin.service';
import _ from 'lodash';

export default {
  name: 'AdminCountry',
  mixins: [loading.default],
  components: {},
  data: () => ({
    countries: [],
    selectedCountry: null
  }),
  methods: {
    getCountries() {
      AdminService.getCountries().then(
        result => {
          this.countries = result.data;
        },
        error => {
          console.log(error);
        }
      );
    },
    updateCountries() {
      this.triggerLoading();

      AdminService.updateCountries(this.visitedCountries)
        .then(() => {
          this.disableLoading();
          this.setAlertColor('success');
          this.setMessage('Successfully updated');
          this.triggerAlert();
        })
        .catch(error => {
          this.setAlertColor('error');
          this.setMessage(error?.response?.data?.error?.message || error?.response?.data?.error || 'Something went wrong');
          this.disableLoading();
          this.triggerAlert();
        });
    },
    removeCountry(id) {
      this.countries.filter(c => c.id_country === id)[0].visited = 0;
    },
    addSelectedCountry() {
      this.countries.filter(c => c.id_country === this.selectedCountry)[0].visited = 1;
    }
  },
  created() {
    this.getCountries();
  },
  computed: {
    visitedCountries: function () {
      return _.orderBy(
        this.countries.filter(c => c.visited == '1'),
        ['slug'],
        ['asc']
      );
    },
    availableCountries: function () {
      return _.orderBy(
        this.countries.filter(c => c.visited == '0'),
        ['slug'],
        ['asc']
      );
    }
  }
};
</script>

<style lang="css" scoped></style>
