<template>
  <div>
    <h6 class="sidebar-title px-3 mt-4">{{ title }}</h6>
    <v-treeview :items="menuItems" transition hoverable color="accent" class="pa-2">
      <template v-slot:prepend="{ item }">
        <v-icon small>{{ item.icon }}</v-icon>
      </template>
      <template v-slot:label="{ item }">
        <router-link :to="{ name: item.link }" v-if="item.link" class="no-decor">{{ item.name }}</router-link>
        <span v-else>{{ item.name }}</span>
      </template>
    </v-treeview>
  </div>
</template>

<script>
export default {
  name: 'SideBar',
  props: {
    menuItems: { type: Array },
    authRole: { type: Array },
    title: { type: String }
  }
};
</script>

<style lang="scss" scoped>
.v-treeview-node__label > a {
  color: var(--primary);
}
</style>
