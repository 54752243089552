const keyHandler = {
  data: () => ({}),
  components: {},
  methods: {
    handleKeydown(e) {
      switch (e.keyCode) {
        case 27:
          this.selectedImage = null;
          break;
      }
    }
  },
  beforeMount() {
    window.addEventListener('keydown', this.handleKeydown, null);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown);
  }
};

export default keyHandler;
