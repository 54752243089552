<template lang="html">
  <footer>
    <v-container fluid>
      <v-divider></v-divider>
      <v-container fluid class="footer mt-10">
        <v-row>
          <v-col cols="12" sm="6" lg="3" class="footer__menu us">
            <h3 class="h3-footer">Kdo Jsme</h3>
            <div class="pointer">
              <img @click="showAboutUs()" src="/imgs/zp_about_us.png" alt="" />
            </div>
          </v-col>
          <v-col cols="12" sm="6" lg="3" class="footer__menu burgers">
            <h3 class="h3-footer">Burger Tour</h3>
            <div>
              <ul class="burgers__menu">
                <template v-for="(burger, index) in burgers">
                  <li v-if="index < 7" :key="index" class="burgers__menu_item">
                    <router-link :to="{ name: 'burger-detail', params: { slug: burger.slug } }" class="no-decor">
                      {{ burger.place }} - {{ burger.city }} ({{ burger.country_name }})
                    </router-link>
                  </li>
                </template>
              </ul>
            </div>
          </v-col>
          <v-col cols="12" sm="6" lg="3" class="footer__menu places">
            <h3 class="h3-footer">Kde už jsme byli</h3>
            <div>
              <ul class="places__menu">
                <template v-for="country in countryStats">
                  <li :key="country.id" class="places__menu_item pointer" @click="routeCountry(country)">
                    <!-- <router-link :to="{ name: 'country', params: { country } }" class="no-decor"> {{ country.name }} ({{ country.items }}) </router-link> -->
                    {{ country.country_name }} ({{ country.items }})
                  </li>
                </template>
              </ul>
            </div>
          </v-col>
          <v-col cols="12" sm="6" lg="3" class="footer__menu wisdom">
            <h3 class="h3-footer">Trocha toho moudra</h3>
            <v-carousel cycle hide-delimited-background hide-delimiters prev-icon next-icon continuous height="auto" :interval="4000">
              <v-carousel-item v-for="quote in quotes" :key="quote.id">
                <div class="wisdom__quote">“{{ quote.quote }}”</div>
                <div class="wisdom__author">{{ quote.author }}</div>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
        <v-divider class="my-4"></v-divider>
        <small> © ZP on the road ... since 2014 | “Once a year, go someplace you've never been before.” (Dalai Lama) </small>
      </v-container>

      <!-- about us -->
      <v-overlay v-if="aboutUs" opacity="0.9">
        <v-card light class="about_us_details pa-5" @click="aboutUs = false">
          <v-toolbar color="secondary" dark style="z-index: -1 !important">
            <v-toolbar-title>Kdo jsme</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="aboutUs = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row justify="center" align="center" class="text-center my-7 us_detail">
              <img @click="showAboutUs()" src="/imgs/zp_about_us.png" alt="" />
            </v-row>
            <v-row justify="center" align="center" style="width: 40vw" class="mx-auto pt-5">
              <v-col cols="12" sm="6" md="4" class="text-center">
                <h2>{{ yearsTogether }} let</h2>
                <span color="secondary">společně cestujeme</span>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="text-center">
                <h2>{{ seenCountries }} zemí</h2>
                <span>jsme viděli</span>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="text-center">
                <h2>4 kontinenty</h2>
                <span>jsme navštívili</span>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col class="px-5">
                <h4 class="text-center mb-4">Ahoj, jsme Zůza a Petr :)</h4>
                <p style="text-indent: 10px">
                  Tenhle blog jsme založili když jsme se přestěhovali na Filipíny. Původně měl tento blog sloužit jako spojka mezi náma a našema rodinama, ale
                  postupně to přerostlo v jakousi naši webovou online galerii. Odkládáme si zde fotky z našich cest, mapujeme navštívené země a v neposlední
                  řadě hledáme ten nejlepší burger pod sluncem :)
                </p>
                <p style="text-indent: 10px">
                  Nejsme žádní blogeři, influenceři, ani nic podobného - moc tipů na super ubytka, jak ušetřit atd tu ani nehledejte; nenašli byste. Původně
                  jsme měli i sekci s článkama, nicméně postupem času jsme se rozhodli nechat pouze fotky.
                </p>
                <p class="text-right">Milujeme cestování po světě, dobré jídlo a vrháme se vstříc dobrodružství!</p>
              </v-col>
            </v-row>
            <v-row justify="end" align="center">
              <v-btn @click="aboutUs = false">Zavřít</v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-overlay>
    </v-container>
  </footer>
</template>

<script>
import { keyboardHandler } from './../../mixins/index';
import PublicService from './../public.service';
import moment from 'moment';

export default {
  name: 'Footer',
  mixins: [keyboardHandler.default],
  data: () => ({
    serverHost: process.env.VUE_APP_API_BASE_URL,
    countryStats: [],
    quotes: [],
    burgers: [],
    aboutUs: false
  }),
  methods: {
    showAboutUs() {
      this.aboutUs = true;
    },
    getQuotes() {
      PublicService.getQuotes().then(
        result => {
          this.quotes = result.data;
        },
        error => {
          console.log(error);
        }
      );
    },
    handleKeydown(e) {
      switch (e.keyCode) {
        case 27:
          this.aboutUs = false;
          break;
      }
    },
    getCountryStats() {
      PublicService.getCountryStats().then(
        result => {
          this.countryStats = result.data.countryStats;
        },
        error => {
          console.log(error);
        }
      );
    },
    getBurgers() {
      PublicService.getBurgers().then(
        result => {
          moment.locale('cs');
          this.burgers = result.data;
          this.burgers.forEach(b => {
            b.date_tasted = moment(b.date_tasted).format('D. MMMM, YYYY');
            b.full_img_path = this.serverHost + b.picture_path.replace('/uploads', '') + '/' + b.picture_name;
          });
          this.burgers.sort(function (a, b) {
            var c = new Date(a.date_tasted);
            var d = new Date(b.date_tasted);
            return c - d;
          });
        },
        error => {
          console.log(error);
        }
      );
    },
    routeCountry(country) {
      this.$router.push({ name: 'country', params: { slug: country.country_slug }, replace: false });
    }
  },
  created() {
    this.getQuotes();
    this.getBurgers();
    this.getCountryStats();
  },
  computed: {
    yearsTogether() {
      return moment().diff('2013-11-11', 'years', false);
    },
    seenCountries() {
      return this.countryStats.length;
    }
  }
};
</script>

<style lang="css" scoped>
.footer {
  width: 90vw;
}
.footer__menu {
  text-align: center;
}
.us img {
  width: 85%;
}

.us_detail img {
  position: absolute;
  transform: translate(-50%, -35%);
  left: 50%;
}
.about_us_details {
  width: 50vw;
  cursor: default;
}

@media only screen and (max-width: 2000px) {
  .about_us_details {
    width: 50vw;
  }
}

@media only screen and (max-width: 1263px) {
  .about_us_details {
    width: 60vw;
  }
}
@media only screen and (max-width: 960px) {
  .about_us_details {
    width: 80vw;
  }
}
@media only screen and (max-width: 599px) {
  .about_us_details {
    width: 100vw;
  }
  .us_detail img {
    width: 280px;
  }
}

.burgers__menu,
.places__menu {
  list-style-type: none;
  padding: 0;
}
.burgers__menu_item {
  margin: 0 0 0.5rem 0;
  font-size: 1.1rem;
}

.places__menu {
  columns: 2;
}
.places__menu_item {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  margin: 0 0 0.8rem 0;
}

.wisdom__quote {
  font-style: italic;
}

.wisdom__author {
  border-left: 2px solid #ddd;
  padding: 0.5rem 0;
  margin: 1rem 0;
  padding-left: 1rem;
  text-align: left;
}
</style>
