import Vue from 'vue';
import Router from 'vue-router';
import { store } from './../store';

/**
 * PUBLIC
 */

import Home from './../components/Home.vue';
import Galerie from './../components/Galerie.vue';
import GalerieDetail from './../components/GalerieDetail.vue';
import Mapa from './../components/Mapa.vue';
import Country from './../components/Country.vue';
import Burgers from './../components/Burgers.vue';
import BurgerDetail from './../components/BurgerDetail.vue';
import Login from './../components/auth/Login.vue';
import NotFound from './../components/shared/404.vue';

/**
 * PRIVATE
 */
import AdminHome from './../components/admin/Home.vue';
import AdminDashboard from './../components/admin/Dashboard.vue';
// countries
import AdminGalleries from './../components/admin/Galleries.vue';
import AdminHomepagePhotos from './../components/admin/HomepagePhotos.vue';
import AdminGalleriesPhoto from './../components/admin/GalleriesPhoto.vue';
import AdminBurgers from './../components/admin/Burgers.vue';
import AdminCountries from './../components/admin/Country.vue';
import AdminQuotes from './../components/admin/Quotes.vue';
import AdminSettings from './../components/admin/Settings.vue';

Vue.use(Router);

export const router = new Router({
  // mode: 'history',
  routes: [
    { path: '*', redirect: { name: 'home' }, public: true },
    { path: '/', name: 'home', component: Home, public: true, meta: { title: '' } },
    { path: '/not-found', name: 'not-found', component: NotFound, public: true, meta: { title: 'Nenalezeno' } },
    { path: '/galerie', name: 'galerie', component: Galerie, public: true, meta: { title: 'Galerie' } },
    { path: '/galerie/:slug', name: 'galerie-detail', component: GalerieDetail, public: true, meta: { title: 'Galerie' } },
    { path: '/mapa-sveta', name: 'mapa', component: Mapa, public: true, meta: { title: 'Mapa světa' } },
    { path: '/burger-tour', name: 'burgers', component: Burgers, public: true, meta: { title: 'Burger Tour' } },
    { path: '/burger-tour/:slug', name: 'burger-detail', component: BurgerDetail, public: true, meta: { title: 'Burger Tour' } },
    { path: '/zeme/:slug', name: 'country', component: Country, public: true, meta: { title: 'Země' } },
    // admin public (only login)
    { path: '/admin/login', name: 'login', component: Login, public: true, meta: { title: 'Přihlášení' } },
    // protected
    {
      path: '/admin',
      component: AdminHome,
      public: false,
      children: [
        { path: '', name: 'admin-home', component: AdminDashboard, meta: { title: 'Admin' } },
        { path: 'homepage/photos', name: 'admin-homepage-photos', component: AdminHomepagePhotos, meta: { title: 'Admin' } },
        { path: 'galleries', name: 'admin-galleries', component: AdminGalleries, meta: { title: 'Admin' } },
        { path: 'galleries/photos', name: 'admin-galleries-photo', component: AdminGalleriesPhoto, meta: { title: 'Admin' } },
        { path: 'burgers', name: 'admin-burgers', component: AdminBurgers, meta: { title: 'Admin' } },
        { path: 'countries', name: 'admin-countries', component: AdminCountries, meta: { title: 'Admin' } },
        { path: 'quotes', name: 'admin-quotes', component: AdminQuotes, meta: { title: 'Admin' } },
        { path: 'settings', name: 'admin-settings', component: AdminSettings, meta: { title: 'Admin' } }
      ]
    }
  ]
});

/**
 * make sure no other paths but public are accessible by non-logged users
 **/
router.beforeEach((to, from, next) => {
  const publicPages = router.options.routes.filter(r => r.public).map(r => r.path); // array of public pages

  let authRequired = true; // default enforce authentication

  publicPages.forEach(pp => {
    if (pp.includes(':slug')) {
      const baseUrl = pp.split('/');
      const baseTargetUrl = to.path.split('/');

      if (baseTargetUrl[1] && baseUrl[1] === baseTargetUrl[1]) {
        authRequired = false;
        return;
      }
    } else if (pp === to.path) {
      authRequired = false;
      return;
    }
  });

  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in + expired token
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/admin/login');
  } else {
    if (loggedIn) {
      const tokenExpiration = parseJwt(JSON.parse(loggedIn).accessToken).exp;
      if (Date.now() >= tokenExpiration * 1000) {
        store.dispatch('auth/logout').then(
          () => {
            next('/login');
          },
          error => {
            console.log('E ', error);
          }
        );
      }
    }
    next();
  }
});

router.afterEach(to => {
  Vue.nextTick(() => {
    document.title = to.meta.title.length > 0 ? to.meta.title + ' :: Zuza a Petr - Cestovni blog' : 'Zuza a Petr - Cestovni blog';
  });
});

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}
