<template>
  <v-container>
    <Header></Header>
    <v-container class="burger">
      <v-row>
        <v-col min-height="100" cols="12" sm="12" md="6" lg="4" v-for="(burger, index) in burgers" :key="index" transition="fab-transition">
          <v-sheet min-height="250" class="fill-height" color="transparent">
            <v-lazy
              v-model="burger.isActive"
              :options="{
                threshold: 0.5
              }"
              transition="fade-transition"
            >
              <router-link :to="{ name: 'burger-detail', params: { slug: burger.slug } }" class="no-decor">
                <div class="burger__item">
                  <img class="burger__item_image blur" :src="burger.thumb_img_path" alt="" />
                  <div class="content fade">
                    <v-container dense class="rating__preview">
                      <v-row dense>
                        <v-col cols="12" sm="4">Houska:</v-col>
                        <v-col cols="12" sm="6">
                          <v-rating
                            class="star"
                            v-model="burger.houska"
                            color="gray darken-3"
                            background-color="#ccc"
                            empty-icon="$ratingFull"
                            half-increments
                            hover
                            readonly
                          ></v-rating>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="4">Maso:</v-col>
                        <v-col cols="12" sm="6">
                          <v-rating
                            class="star"
                            v-model="burger.maso"
                            color="gray darken-3"
                            background-color="#ccc"
                            empty-icon="$ratingFull"
                            half-increments
                            hover
                            readonly
                          ></v-rating>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="4">Obsluha:</v-col>
                        <v-col cols="12" sm="6">
                          <v-rating
                            class="star"
                            v-model="burger.obsluha"
                            color="gray darken-3"
                            background-color="#ccc"
                            empty-icon="$ratingFull"
                            half-increments
                            hover
                            readonly
                          ></v-rating>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="4">Prostředí:</v-col>
                        <v-col cols="12" sm="6">
                          <v-rating
                            class="star"
                            v-model="burger.prostredi"
                            color="gray darken-3"
                            background-color="#ccc"
                            empty-icon="$ratingFull"
                            half-increments
                            hover
                            readonly
                          ></v-rating>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="4">Hranolky:</v-col>
                        <v-col cols="12" sm="6">
                          <v-rating
                            class="star"
                            v-model="burger.hranolky"
                            color="gray darken-3"
                            background-color="#ccc"
                            empty-icon="$ratingFull"
                            half-increments
                            hover
                            readonly
                          ></v-rating>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </div>
                <div class="burger__item_desc text-center">
                  <h4 class="burger__item_name">{{ burger.place }} - {{ burger.city }} ({{ burger.country_name }})</h4>
                  <div class="burger__item_label">{{ burger.date_tasted }}</div>
                </div>
              </router-link>
            </v-lazy>
          </v-sheet>
        </v-col>
      </v-row>
      <ToTop></ToTop>
    </v-container>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from './shared/Header.vue';
import Footer from './shared/Footer.vue';
import ToTop from './shared/ToTop.vue';
import PublicService from './public.service';
import moment from 'moment';

export default {
  name: 'Burgers',
  components: {
    Header,
    Footer,
    ToTop
  },
  data: () => ({
    serverHost: process.env.VUE_APP_API_BASE_URL,
    burgers: [],
    isActive: false
  }),
  methods: {
    getBurgers() {
      PublicService.getBurgers().then(
        result => {
          moment.locale('cs');
          this.burgers = result.data;
          this.burgers.forEach(b => {
            b.date_tasted = moment(b.date_tasted).format('D. MMMM, YYYY');
            b.full_img_path = this.serverHost + b.picture_path.replace('/uploads', '') + '/' + b.picture_name;
            b.thumb_img_path = this.serverHost + b.picture_path.replace('/uploads', '') + '/thumbnails/' + b.picture_name;
          });
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  created() {
    this.getBurgers();
  },
  computed: {
    visiblePosts() {
      return this.burgers.filter(p => p.isActive).length;
    }
  }
};
</script>

<style lang="css" scoped>
.burger {
  width: 80vw;
}
.burger__item {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 33vh;
}
.burger__item_image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.burger__item > img {
  display: block;
  width: 100%;
  /* aspect-ratio: 1/1; */
  object-fit: cover;
  object-position: center;
}

.burger__item > .content {
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.burger__item > .content.fade {
  opacity: 0;
}
.burger__item:hover > .content.fade {
  opacity: 1;
}
.burger__item:hover > .blur {
  filter: blur(5px);
}
.burger__item:hover .burger__item_name {
  opacity: 1;
}

.burger__item > img,
.burger__item > .content {
  transition: 200ms ease-in-out;
}

.burger__item_name {
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: 500;
}
.burger__item_label {
  color: #5d5d5d;
}

.rating__preview {
  font-size: 1.2rem;
  font-weight: 400;
}
</style>
