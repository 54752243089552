<template>
  <v-container fluid class="main">
    <Header></Header>
    <v-container class="country mt-4" fluid>
      <h2 class="mb-10 text-uppercase text-center">{{ countryItems.country.name }}</h2>

      <v-container v-if="countryItems.galleries.length == 0 && countryItems.burgers.length == 0" class="text-center">
        Hmm, tak tady jsme asi ještě nebyli. Ale třeba se sem brzo vydáme :)

        <v-row>
          <v-col class="text-center mt-10">
            <img src="/imgs/404.png" alt="" />
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="countryItems.galleries.length > 0" fluid>
        <h3 class="ml-10 mb-5 mt-5">Galerie</h3>
        <v-row justify="left" align="center">
          <v-col class="gallery__cover view-item" cols="12" sm="6" md="4" lg="3" v-for="gallery in countryItems.galleries" :key="gallery.id">
            <div class="gallery__cover_item pointer">
              <router-link :to="{ name: 'galerie-detail', params: { slug: gallery.slug } }" class="no-decor">
                <img class="gallery__cover_image" :src="gallery.full_img_path" alt="" />
                <div class="gallery__cover_title text-center">
                  <span class="label">{{ gallery.name }}</span>
                </div>
              </router-link>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="countryItems.burgers.length > 0" fluid>
        <h3 class="ml-10 mb-5 mt-5">Burger Tour</h3>
        <v-row justify="left" align="center">
          <v-col class="view-item" cols="12" sm="6" md="4" lg="3" v-for="(burger, index) in countryItems.burgers" :key="index" transition="fab-transition">
            <router-link :to="{ name: 'burger-detail', params: { slug: burger.slug } }" class="no-decor">
              <div class="burger__item pointerd">
                <img class="burger__item_image blur" :src="burger.full_img_path" alt="" />
                <div class="content fade">
                  <v-container dense class="rating__preview">
                    <div class="burger__item_desc text-center">
                      <h4 class="burger__item_name">{{ burger.place }} - {{ burger.city }}</h4>
                      <div class="burger__item_label">{{ burger.date_tasted }}</div>
                    </div>
                  </v-container>
                </div>
              </div>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
      <ToTop></ToTop>
    </v-container>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from './shared/Header.vue';
import Footer from './shared/Footer.vue';
import ToTop from './shared/ToTop.vue';
import PublicService from './public.service';
import moment from 'moment';

export default {
  name: 'Zeme',
  components: {
    Header,
    Footer,
    ToTop
  },
  data: () => ({
    serverHost: process.env.VUE_APP_API_BASE_URL,
    fab: false,
    countryItems: []
  }),
  methods: {
    getCountryItems(slug) {
      PublicService.getCountryItems(slug).then(
        result => {
          this.countryItems = result.data;
          this.countryItems.burgers.forEach(b => {
            b.date_tasted = moment(b.date_tasted).format('D. MMMM, YYYY');
            b.full_img_path = this.serverHost + b.picture_path.replace('/uploads', '') + '/' + b.picture_name;
          });
          this.countryItems.galleries.forEach(g => {
            g.full_img_path = this.serverHost + g.cover_image.replace('/uploads', '');
          });
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  created() {
    this.$watch(
      () => this.$route.params,
      toParams => {
        this.getCountryItems(toParams.slug);
        this.$vuetify.goTo(0);
      }
    );
    this.getCountryItems(this.$route.params.slug);
    this.$vuetify.goTo(0);
  }
};
</script>

<style lang="css" scoped>
.main {
  padding: 0;
}
.gallery__cover {
  margin: 0;
  padding: 0;
}
.gallery__cover_item {
  position: relative;
  transition: 0.2s;
  overflow: hidden;
  height: 20vw;
}

@media only screen and (max-width: 2000px) {
  .gallery__cover_item {
    height: 18vw;
  }
}
@media only screen and (max-width: 1263px) {
  .gallery__cover_item {
    height: 30vw;
  }
}
@media only screen and (max-width: 960px) {
  .gallery__cover_item {
    height: 40vw;
  }
}
@media only screen and (max-width: 599px) {
  .gallery__cover_item {
    height: 70vw;
  }
}

.gallery__cover_image {
  width: 100%;
  display: block;
  transition: 0.2s;
}
.gallery__cover_title {
  color: #000000;
  background: #ccc;
  width: 50%;
  margin: 0 auto;
  border-radius: 5px;
  padding: 0.3rem;
  position: absolute;
  inset: 0;
  top: auto;
  transition: 0.2s;
  opacity: 0.7;
  bottom: 1.5rem;
}

.gallery__cover_title .label {
  opacity: 1;
  font-weight: 400;
}

.gallery__cover_item:hover {
  transition: 0.2s;
  z-index: 10;
}
.gallery__cover_item:hover .gallery__cover_title {
  color: #222;
  background: rgb(255, 255, 255, 0.8);
  transition: 0.2s;
  transform: translate(0, -1rem);
  font-weight: 600;
  opacity: 1;
}
.gallery__cover_item:hover .gallery__cover_image {
  transition: 0.2s;
  transform: scale(1.1);
  z-index: 10;
}

/* burgers */
.burger {
  height: 80vw;
}
.burger__item {
  position: relative;
  overflow: hidden;
  height: 20vw;
}
@media only screen and (max-width: 2000px) {
  .burger__item {
    height: 18vw;
  }
}
@media only screen and (max-width: 1263px) {
  .burger__item {
    height: 30vw;
  }
}
@media only screen and (max-width: 960px) {
  .burger__item {
    height: 40vw;
  }
}
@media only screen and (max-width: 599px) {
  .burger__item {
    height: 70vw;
  }
}
.burger__item > img {
  display: block;
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center;
}

.burger__item > .content {
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.burger__item > .content.fade {
  opacity: 0;
}
.burger__item:hover > .content.fade {
  opacity: 1;
}
.burger__item:hover > .blur {
  filter: blur(5px);
}
.burger__item:hover .burger__item_name {
  opacity: 1;
}

.burger__item > img,
.burger__item > .content {
  transition: 200ms ease-in-out;
}

.burger__item_image {
  width: 100%;
}
.burger__item_name {
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: 500;
}
.burger__item_label {
  color: #5d5d5d;
}

.rating__preview {
  font-size: 1.2rem;
  font-weight: 400;
}
</style>
