<template>
  <v-container fluid class="main">
    <Header></Header>
    <v-container class="gallery mt-4" fluid>
      <h2 class="mb-10 text-uppercase text-center">{{ galImages.gallery.name }}</h2>
      <v-container class="descr">
        {{ galImages.gallery.description }}
      </v-container>
      <v-container class="photos" fluid>
        <v-row>
          <!-- eslint-disable -->
          <transition-group
            name="view"
            appear
            tag="div"
            class="transition_box"
            justify="center"
            align="center"
            :style="{ '--total': galImages.galleryPhotos.length }"
          >
            <v-col
              class="gallery__cover view-item"
              cols="12"
              sm="6"
              md="4"
              lg="2"
              v-for="(image, i) in galImages.galleryPhotos"
              :style="{ '--i': i }"
              :key="image.id_photo"
              v-if="showItems && i < limit"
            >
              <div class="gallery__cover_item pointer">
                <img v-on="on" loading class="gallery__cover_image" :src="image.thumb_img_path" alt="" @click="selectedImage = image" />
              </div>
            </v-col>
          </transition-group>
          <!-- eslint-enable -->
        </v-row>
      </v-container>

      <v-overlay v-if="selectedImage" opacity="0.9">
        <v-container fluid>
          <v-img
            v-touch="{
              left: () => swipe('left', selectedImage),
              right: () => swipe('right', selectedImage),
              down: () => swipe('down', selectedImage)
            }"
            class="gallery_image_detail ma-0 pa-0"
            transition="slide-x-transition"
            contain
            :key="selectedImage.id_photo"
            :src="selectedImage ? selectedImage.full_img_path : ''"
            @click="selectedImage = null"
          >
            <div v-if="selectedImage.description" class="text-center gallery_image_title py-2 mb-4">
              <span class="gallery_image_title_text">{{ selectedImage.description }}</span>
            </div>
          </v-img>
        </v-container>
      </v-overlay>

      <div v-if="selectedImage" class="gallery_image_nav prev pointer" @click="prevImage(selectedImage)">
        <v-icon size="40" color="grey lighten-3">mdi-chevron-left</v-icon>
      </div>
      <div v-if="selectedImage" class="gallery_image_nav next pointer" @click="nextImage(selectedImage)">
        <v-icon size="40" color="grey lighten-3">mdi-chevron-right</v-icon>
      </div>
      <ToTop></ToTop>
    </v-container>
    <Footer></Footer>
  </v-container>
</template>

<script>
import { keyboardHandler } from '../mixins/index';
import Header from './shared/Header.vue';
import Footer from './shared/Footer.vue';
import ToTop from './shared/ToTop.vue';
import PublicService from './public.service';

export default {
  name: 'Galerie',
  mixins: [keyboardHandler.default],
  components: {
    Header,
    Footer,
    ToTop
  },
  data: () => ({
    showItems: false,
    serverHost: process.env.VUE_APP_API_BASE_URL,
    galImages: [],
    selectedImage: null,
    swipeDirection: 'None',
    dialog: false
  }),
  methods: {
    nextImage(img) {
      let currentIndex = this.galImages.galleryPhotos.findIndex(i => i.id_photo == img.id_photo);

      if (currentIndex === this.galImages.galleryPhotos.length - 1) {
        currentIndex = 0;
      } else {
        currentIndex += 1;
      }
      this.selectedImage = this.galImages.galleryPhotos[currentIndex];
    },
    prevImage(img) {
      let currentIndex = this.galImages.galleryPhotos.findIndex(i => i.id_photo == img.id_photo);

      if (currentIndex === 0) {
        currentIndex = this.galImages.galleryPhotos.length - 1;
      } else {
        currentIndex -= 1;
      }
      this.selectedImage = this.galImages.galleryPhotos[currentIndex];
    },
    handleKeydown(e) {
      switch (e.keyCode) {
        case 37:
          this.prevImage(this.selectedImage);
          break;
        case 39:
          this.nextImage(this.selectedImage);
          break;
        case 27:
          this.selectedImage = null;
          break;
      }
    },
    getGalleryDetails(slug) {
      PublicService.getGalleryDetails(slug).then(
        result => {
          this.galImages = result.data;

          const slug = this.galImages.gallery.slug;

          this.galImages.galleryPhotos.forEach(g => {
            g.full_img_path = this.serverHost + g.photo_path.replace('/uploads', '') + '/' + g.photo_name;
            g.thumb_img_path = this.serverHost + g.photo_path.replace('/uploads', '').replace(slug, `${slug}/thumbnails`) + '/' + g.photo_name;
          });
        },
        error => {
          console.log(error);
        }
      );
    },
    swipe(direction, img) {
      this.swipeDirection = direction;
      if (this.swipeDirection === 'left') this.nextImage(img);
      if (this.swipeDirection === 'right') this.prevImage(img);
      if (this.swipeDirection === 'down') this.selectedImage = null;
    }
  },
  mounted() {
    this.getGalleryDetails(this.$route.params.slug);
  },
  created() {
    // this.gallery = this.$route.params.gallery;
    // if (!this.gallery) this.$router.push({ name: 'galerie' });
    this.$vuetify.goTo(0);
    this.$nextTick(() => {
      this.showItems = true;
    });
  },
  computed: {
    limit() {
      return this.galImages.galleryPhotos.length;
    }
  }
};
</script>

<style lang="css" scoped>
.gallery_image_title_text {
  text-shadow: 1px 1px 1px #2e2e2e;
  background: rgba(0, 0, 0, 0.3);
  padding: 0.3rem 0.75rem;
  border-radius: 5px;
}

.v-overlay--active {
  touch-action: none;
}

.image_title {
  display: block;
  top: 0;
  bottom: -100px;
}

.main {
  padding: 0;
}
.gallery {
  padding: 0;
}
.gallery__cover {
  margin: 0;
  padding: 0;
}
@media only screen and (max-width: 2000px) {
  .gallery__cover_item {
    height: 18vw;
  }
}
@media only screen and (max-width: 1263px) {
  .gallery__cover_item {
    height: 30vw;
  }
}
@media only screen and (max-width: 960px) {
  .gallery__cover_item {
    height: 40vw;
  }
}
@media only screen and (max-width: 599px) {
  .gallery__cover_item {
    height: 70vw;
  }
  .next,
  .prev {
    display: none;
  }
  .gallery_image_detail {
    width: 100vw;
  }
  .gallery_image_title {
    background: rgba(93, 93, 93, 0.9);
    color: rgb(247, 247, 247);
    width: 100vw;
    display: flex;
    justify-content: center;
    align-content: bottom;
    padding: 0 1rem;
  }
}
.gallery__cover_title {
  color: #000000;
  background: #ccc;
  width: 50%;
  margin: 0 auto;
  border-radius: 5px;
  padding: 0.3rem;
  position: absolute;
  inset: 0;
  top: auto;
  transition: 0.2s;
  opacity: 0.7;
  bottom: 1.5rem;
}

.gallery__cover_title .label {
  opacity: 1;
  font-weight: 400;
}

.gallery_image_nav {
  z-index: 5;
  color: #e0e0e0;
  position: fixed;
  border-radius: 50%;
  top: 50%;
  background: rgba(161, 161, 161, 0.3);
  /* transition: background-color 0.3s; */
}
/* .gallery_image_nav:hover {
  background: rgba(161, 161, 161, 0.6);
  transition: background-color 0.3s;
} */
.prev {
  left: 2rem;
}
.next {
  right: 2rem;
}
</style>
