<template>
  <v-container>
    <Header></Header>
    <v-container class="not-found mt-4" fluid>
      <h2 class="mb-10 text-center">Oooops, kampak se nám to ztratilo?</h2>
      <v-row>
        <v-col class="text-center">
          <img src="/imgs/404.png" alt="" />
        </v-col>
      </v-row>
    </v-container>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from './Header.vue';
import Footer from './Footer.vue';

export default {
  name: 'NotFound',
  components: {
    Header,
    Footer
  },
  data: () => ({
    message: null
  }),
  methods: {},
  created() {
    this.message = this.$route.params.message;
  }
};
</script>

<style lang="css" scoped></style>
