<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data: () => ({})
};
</script>

<style lang="scss">
@import './App.scss';
</style>
