import Snackbar from './../components/alerts/Snackbar.vue';

const loadingHandler = {
  data: () => ({
    loading: false,
    alert: false,
    msg: null,
    color: null
  }),
  components: {
    Snackbar
  },
  methods: {
    triggerLoading() {
      this.loading = true;
    },
    disableLoading() {
      this.loading = false;
    },
    triggerAlert() {
      this.alert = true;
      window.setInterval(() => {
        this.alert = false;
      }, 5000);
    },
    setMessage(msg) {
      this.msg = msg;
    },
    setAlertColor(color) {
      this.color = color;
    }
  }
};

export default loadingHandler;
