import axios from '../../axios/index';
import authHeader from '../auth/auth.header';

class AdminService {
  // countries
  getCountries() {
    return axios.get('/admin/countries', { headers: authHeader() });
  }
  updateCountries(countries) {
    return axios.put('/admin/countries', { countries }, { headers: authHeader() });
  }

  // quote
  addQuote(quote) {
    return axios.post('admin/quotes', quote, { headers: authHeader() });
  }
  deleteQuote(id) {
    return axios.delete(`admin/quotes/${id}`, { headers: authHeader() });
  }

  // burgers
  addBurger(burger) {
    return axios.post(`admin/burgers`, burger, { headers: authHeader() });
  }
  deleteBurger(id) {
    return axios.delete(`admin/burgers/${id}`, { headers: authHeader() });
  }
  editBurger(id, burger) {
    return axios.put(`admin/burgers/${id}`, burger, { headers: authHeader() });
  }

  // galleries
  addGallery(gallery) {
    return axios.post(`admin/galleries`, gallery, { headers: authHeader() });
  }
  updateGalleryOrder(payload) {
    return axios.put(`admin/galleries/order`, payload, { headers: authHeader() });
  }
  deleteGallery(id) {
    return axios.delete(`admin/galleries/${id}/`, { headers: authHeader() });
  }
  editGallery({ id, payload }) {
    return axios.put(`admin/galleries/${id}/`, payload, { headers: authHeader() });
  }
  uploadPhotos(id, photo, onUploadProgress) {
    const formData = new FormData();
    formData.append(`photo`, photo);
    return axios.post(`admin/galleries/${id}/photos`, formData, { headers: authHeader(), onUploadProgress });
  }
  deletePhoto(gid, pid) {
    return axios.delete(`admin/galleries/${gid}/photos/${pid}`, { headers: authHeader() });
  }
  editPhoto(gid, pid, payload) {
    return axios.put(`admin/galleries/${gid}/photos/${pid}`, payload, { headers: authHeader() });
  }
  updatePhotoOrder(payload, gid) {
    return axios.patch(`admin/galleries/${gid}/photos/order`, payload, { headers: authHeader() });
  }

  // home page gallery
  uploadHomePagePhotos(photo, onUploadProgress) {
    const formData = new FormData();
    formData.append(`photo`, photo);
    return axios.post(`admin/homepage/photos`, formData, { headers: authHeader(), onUploadProgress });
  }
  deleteHomePagePhoto(pid) {
    return axios.delete(`admin/homepage/photos/${pid}`, { headers: authHeader() });
  }

  // stats dashboard
  failedLogins() {
    return axios.get(`admin/dashboard/failed-logins`, { headers: authHeader() });
  }

  // settings
  changePassword(payload) {
    return axios.put('admin/password/change', { password: payload }, { headers: authHeader() });
  }
}

export default new AdminService();
