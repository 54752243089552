<template>
  <v-container>
    <Snackbar :alert="alert" :msg="msg" :color="color"></Snackbar>
    <h1>{{ gallery.gallery }} photos</h1>
    <v-row>
      <v-col class="text-right">
        <v-btn class="my-3 ml-auto grey lighten-4" @click="openUploadModal()">
          <v-icon left> mdi-image-plus </v-icon>
          Upload photos
        </v-btn>
      </v-col>
    </v-row>
    <!-- save button -->
    <transition name="fade">
      <v-row v-if="orderChanged">
        <v-col class="text-right">
          <v-btn class="ml-auto accent" @click="updatePhotoOrder()">
            <v-icon left> mdi-content-save </v-icon>
            Save photos order
          </v-btn>
        </v-col>
      </v-row>
    </transition>

    <!-- UPLOAD DIALOG -->
    <v-dialog v-model="uploadModal" max-width="60%" transition="dialog-bottom-transition">
      <v-card min-height="450">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Upload photos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="uploadModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form class="pb-5">
          <v-container fill-height fluid>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="6">
                <v-file-input
                  prepend-icon="mdi-image"
                  hint="Only accepts png, jpg, jpeg and each file must have max 10MB."
                  persistent-hint
                  show-size
                  multiple
                  v-model="gallery_photos"
                  label="Select your photos ..."
                  truncate-length="50"
                ></v-file-input>
              </v-col>
              <v-col cols="12" sm="2">
                <v-btn :loading="loading" :disabled="gallery_photos.length == 0" @click.prevent="uploadPhotos()">
                  Upload <v-icon right dark>mdi-cloud-upload</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
          <v-fade-transition>
            <v-container v-if="progressInfos.length > 0">
              <v-row align="center" justify="center">
                <v-col cols="12" sm="10"><div class="subtitle">Progress</div> </v-col>
              </v-row>
              <v-row class="mx-O px-0" v-for="(progressInfo, index) in progressInfos" :key="index" dense justify="center" align="center">
                <v-col cols="12" sm="6" dense>
                  <span style="font-size: 0.8rem">{{ progressInfo.fileName }}</span>
                  <v-progress-linear v-model="progressInfo.percentage" dense color="light-blue" height="12" reactive>
                    <strong style="font-size: 0.6rem">{{ progressInfo.percentage }} %</strong>
                  </v-progress-linear>
                </v-col>
              </v-row>
            </v-container>
          </v-fade-transition>
        </v-form>
      </v-card>
    </v-dialog>

    <v-divider class="my-5"></v-divider>

    <v-card>
      <v-container class="grey lighten-2">
        <v-row>
          <v-col>Photos</v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right pointer" style="font-size: 0.8rem" @click="sortPhotos(photos)">
            Sort <v-icon small>mdi-sort-alphabetical-ascending</v-icon>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid class="photos py-10" v-if="photos.length > 0">
        <v-row justify="center">
          <v-col cols="12" sm="3">
            <v-row>
              <v-col cols="12" sm="3"><strong>Cover Photo:</strong></v-col>
              <v-col cols="12" sm="9">
                <img loading style="width: 75%" :src="getCoverPhoto(photos).thumb_img_path" alt="" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" sm="12">
                <h3>{{ gallery.country_name }}</h3>
              </v-col>
              <v-col cols="12" sm="12"> {{ gallery.description }} </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-container fluid class="photos py-10" v-if="photos.length == 0">
        <v-row justify="center">
          <v-col cols="12" sm="4"> ... no photos yet ... upload some awesome memories :) </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-container fluid class="photos py-10" v-if="photos.length > 0">
        <draggable v-model="photos" class="row justify-center" :sort="true" @change="reorderedPhotos(photos)">
          <v-col class="gallery__cover" cols="20" sm="6" md="4" lg="2" v-for="photo in photos" :key="photo.id_photo">
            <v-tooltip top :disabled="!photo.description">
              <template v-slot:activator="{ on }">
                <img v-on="on" loading class="gallery__cover_image pointer" :src="photo.thumb_img_path" alt="" @click="setSelectedImage(photo)" />
              </template>
              <span>{{ photo.description }}</span>
            </v-tooltip>
          </v-col>
        </draggable>
      </v-container>

      <!-- Photo details -->
      <v-overlay v-if="selectedImage.id_photo !== null" opacity="0.9">
        <v-card light>
          <v-toolbar color="accent" dark>
            <v-toolbar-title>Photo details</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="clearSelectedImage()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <!-- <v-card-actions>
            <v-btn @click="rotateLeft()"> <v-icon left>mdi-rotate-left</v-icon> Rotate left</v-btn>
            <v-btn @click="rotateRight()"> <v-icon left>mdi-rotate-right</v-icon> Rotate right</v-btn> 
          </v-card-actions> -->
          <v-card-text>
            <v-row justify="center" align="center" class="mx-4 px-4">
              <v-col cols="12" sm="2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="prevImage(selectedImage)"> <v-icon left>mdi-chevron-left</v-icon> Prev </v-btn>
                  </template>
                  <span>Previous photo</span>
                </v-tooltip>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="nextImage(selectedImage)"> Next <v-icon right>mdi-chevron-right</v-icon></v-btn>
                  </template>
                  <span>Next photo</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" sm="6">
                <v-img
                  class="gallery_image_detail_admin"
                  :style="[selectedImage.rotation ? { transform: 'rotate(' + selectedImage.rotation + 'deg)' } : '']"
                  contain
                  :src="selectedImage.full_img_path"
                ></v-img
              ></v-col>
              <v-divider vertical></v-divider>
              <v-col cols="12" sm="3">
                <v-text-field v-model="selectedImage.photo_name" readonly label="Photo name"></v-text-field>
                <v-checkbox v-model="selectedImage.cover_photo" label="Cover photo"></v-checkbox>
                <v-textarea
                  row-height="30"
                  outlined
                  auto-grow
                  v-model="selectedImage.description"
                  max-length="250"
                  counter="250"
                  label="Description"
                ></v-textarea>
                <v-btn color="primary" @click="editPhoto({ selectedImage, gallery })">Save</v-btn>
                <v-btn color="error" class="ml-10" text @click="deletePhotoOpenModal()">Delete</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-overlay>
    </v-card>

    <!-- DELETE PHOTO DIALOG -->
    <v-dialog v-model="deletePhotoModal" max-width="60%" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar color="error" dark>
          <v-toolbar-title>Delete photo</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="deletePhotoModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form class="pb-5">
          <v-container fill-height fluid>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="6">
                <v-row align="center" justify="center" class="my-5">
                  <v-col v-if="selectedImage">
                    <p class="mb-5 text-center">Do you really want to delete photo "{{ selectedImage.photo_name }}"?</p>
                    <img :src="selectedImage.full_img_path" style="width: 15vw" class="mx-auto d-flex" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn app class="error mx-2" :loading="loading" tabindex="90" @click="deletePhoto()"> Delete </v-btn>
                    <v-btn app class="secondary" tabindex="99" @click="deletePhotoModal = false">Cancel</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { loading, keyboardHandler } from '../../mixins/index';
import AdminService from './admin.service';
import draggable from 'vuedraggable';
import PublicService from './../public.service';

export default {
  name: 'AdminGalleriesPhoto',
  mixins: [loading.default, keyboardHandler.default],
  components: { draggable },
  data: () => ({
    serverHost: process.env.VUE_APP_API_BASE_URL,
    gallery: null,
    uploadModal: false,
    gallery_photos: [],
    sorted_gallery_photos: [],
    progressInfos: [],
    deletePhotoModal: false,
    orderChanged: false,
    selectedImage: {
      id_photo: null,
      id_gallery: null,
      description: null,
      photo_path: null,
      photo_name: null,
      cover_photo: null,
      full_img_path: null,
      rotation: 0
    },
    message: '',
    photos: [],
    image_limits: {
      type: ['image/png', 'image/jpeg'],
      size: 10485760 // 10MB
    }
  }),
  methods: {
    nextImage(img) {
      let currentIndex = this.photos.findIndex(i => i.id_photo == img.id_photo);

      if (currentIndex === this.photos.length - 1) {
        currentIndex = 0;
      } else {
        currentIndex += 1;
      }
      this.selectedImage = this.photos[currentIndex];
    },
    prevImage(img) {
      let currentIndex = this.photos.findIndex(i => i.id_photo == img.id_photo);

      if (currentIndex === 0) {
        currentIndex = this.photos.length - 1;
      } else {
        currentIndex -= 1;
      }
      this.selectedImage = this.photos[currentIndex];
    },
    sortPhotos(array) {
      array
        .sort((a, b) => a.photo_name.localeCompare(b.photo_name))
        .forEach((g, i) => {
          this.sorted_gallery_photos.push({
            photo_order: i + 1,
            photo_name: g.photo_name,
            id_photo: g.id_photo
          });
        });
      this.orderChanged = true;
    },
    reorderedPhotos(array) {
      this.sorted_gallery_photos = [];
      array.forEach((g, i) => {
        this.sorted_gallery_photos.push({
          photo_order: i + 1,
          photo_name: g.photo_name,
          id_photo: g.id_photo
        });
      });
      this.orderChanged = true;
    },
    updatePhotoOrder() {
      AdminService.updatePhotoOrder(this.sorted_gallery_photos, this.gallery.id_gallery)
        .then(() => {
          this.setAlertColor('primary');
          this.setMessage('Photo order saved');
          this.triggerAlert();
        })
        .catch(error => {
          console.log(error);
          this.setAlertColor('error');
          this.setMessage(error?.response?.data?.error?.message || error?.response?.data?.error || 'Something went wrong');
          this.triggerAlert();
        });
      this.orderChanged = false;
    },
    openUploadModal() {
      this.uploadModal = true;
    },
    clearSelectedImage() {
      this.selectedImage = {
        id_photo: null,
        id_gallery: null,
        description: null,
        photo_path: null,
        photo_name: null,
        cover_photo: null,
        full_img_path: null,
        rotation: 0
      };
    },
    setSelectedImage(photo) {
      this.selectedImage = {
        id_photo: photo.id_photo,
        id_gallery: photo.id_gallery,
        description: photo.description,
        photo_path: photo.photo_path,
        photo_name: photo.photo_name,
        cover_photo: photo.cover_photo,
        full_img_path: photo.full_img_path,
        rotation: 0
      };
    },
    rotateLeft() {
      if (this.selectedImage.rotation == -270) {
        this.selectedImage.rotation = 0;
      } else {
        this.selectedImage.rotation = this.selectedImage.rotation - 90;
      }
    },
    rotateRight() {
      if (this.selectedImage.rotation == 270) {
        this.selectedImage.rotation = 0;
      } else {
        this.selectedImage.rotation = this.selectedImage.rotation + 90;
      }
    },
    upload(index, photo) {
      this.progressInfos[index] = { percentage: 0, fileName: photo.name };

      const id_gallery = this.gallery.id_gallery;

      AdminService.uploadPhotos(id_gallery, photo, event => {
        this.progressInfos[index].percentage = Math.round((100 * event.loaded) / event.total);
      })
        .then(response => {
          let prevMessage = this.message ? this.message + '\n' : '';
          this.message = prevMessage + response.data.message;
          return this.getGalleryPhotos(id_gallery);
        })
        .then(files => {
          this.photos = files?.data;
        })
        .catch(error => {
          console.log(error);
          this.progressInfos[index].percentage = 0;
          this.message = `Could not upload the image: ${photo.name}`;
          this.disableLoading();
        })
        .finally(() => {
          this.disableLoading();
          setTimeout(
            function () {
              this.progressInfos = [];
            }.bind(this),
            2000
          );
        });
    },
    uploadPhotos() {
      this.triggerLoading();

      if (this.gallery_photos.length === 0) {
        this.setAlertColor('error');
        this.setMessage(`You have to select some photos.`);
        this.disableLoading();
        this.triggerAlert();
        return;
      }

      this.gallery_photos.forEach(photo => {
        if (!this.image_limits.type.includes(photo.type)) {
          this.setAlertColor('error');
          this.setMessage(`Invalid file type: ${photo.name} (${photo.type})`);
          this.disableLoading();
          this.triggerAlert();
          return;
        }

        if (photo.size > this.image_limits.size) {
          this.setAlertColor('error');
          this.setMessage(`Invalid file size: ${photo.name} (${photo.size / 1024 / 1024})`);
          this.disableLoading();
          this.triggerAlert();
          return;
        }
      });

      // trigger upload
      this.gallery_photos.forEach((photo, index) => {
        this.upload(index, photo);
      });

      this.gallery_photos = [];
    },
    getGalleryPhotos(id) {
      PublicService.getGalleryPhotos(id).then(
        result => {
          this.photos = result?.data;
          this.photos.forEach(p => {
            const slug = this.gallery.slug;
            p.full_img_path = this.serverHost + p.photo_path.replace('/uploads', '') + '/' + p.photo_name;
            p.thumb_img_path = this.serverHost + p.photo_path.replace('/uploads', '').replace(slug, `${slug}/thumbnails`) + '/' + p.photo_name;
          });
        },
        error => {
          console.log(error);
        }
      );
    },
    deletePhotoOpenModal() {
      this.deletePhotoModal = true;
    },
    deletePhoto() {
      AdminService.deletePhoto(this.gallery.id_gallery, this.selectedImage.id_photo)
        .then(() => {
          this.setAlertColor('primary');
          this.setMessage('Photo deleted');
          this.triggerAlert();
          this.deletePhotoModal = false;
          this.clearSelectedImage(); // close the overlay
          this.getGalleryPhotos(this.gallery.id_gallery);
        })
        .catch(error => {
          console.log(error);
          this.setAlertColor('error');
          this.setMessage(error?.response?.data?.error?.message || error?.response?.data?.error || 'Something went wrong');
          this.triggerAlert();
        });
    },
    getCoverPhoto(photos) {
      return photos.filter(p => p.cover_photo)[0];
    },
    editPhoto(params) {
      const { selectedImage, gallery } = params;

      if (this.descriptionLength > 250) {
        this.setAlertColor('error');
        this.setMessage(`Description is longer than allowed`);
        this.disableLoading();
        this.triggerAlert();
        return;
      }

      const payload = {
        cover_photo: selectedImage.cover_photo,
        description: selectedImage.description
      };

      AdminService.editPhoto(gallery.id_gallery, selectedImage.id_photo, payload)
        .then(() => {
          this.setAlertColor('primary');
          this.setMessage('Photo saved');
          this.triggerAlert();
          // this.clearSelectedImage(); // close the overlay
          this.getGalleryPhotos(this.gallery.id_gallery);
        })
        .catch(error => {
          console.log(error);
          this.setAlertColor('error');
          this.setMessage(error?.response?.data?.error?.message || error?.response?.data?.error || 'Something went wrong');
          this.triggerAlert();
        });
    },
    handleKeydown(e) {
      switch (e.keyCode) {
        // case 37:
        //   this.prevImage(this.selectedImage);
        //   break;
        // case 39:
        //   this.nextImage(this.selectedImage);
        //   break;
        case 27:
          this.clearSelectedImage();
          break;
      }
    }
  },
  created() {
    this.gallery = this.$route.params.gallery;
    if (!this.gallery) this.$router.push({ name: 'admin-galleries' });
    this.getGalleryPhotos(this.gallery.id_gallery);
  },
  computed: {
    descriptionLength() {
      return this.selectedImage.description ? this.selectedImage.description.length : 0;
    }
  }
};
</script>

<style lang="css" scoped>
.photos {
  padding: 1rem;
}
.cover_label {
  position: absolute;
  background: white;
  z-index: 20;
  right: 0;
}
.gallery__cover {
  margin: 0.1rem;
  padding: 0;
  height: 250px;
}
</style>
