import axios from '../axios/index';

class PublicService {
  getQuotes() {
    return axios.get('/quotes');
  }

  getBurgers() {
    return axios.get('/burgers');
  }

  getBurgerDetails(slug) {
    return axios.get(`/burgers/${slug}`);
  }

  getGalleries() {
    return axios.get('/galleries');
  }

  getGalleryPhotos(id) {
    return axios.get(`/galleries/${id}/photos`);
  }

  getGalleryDetails(slug) {
    return axios.get(`/galleries/${slug}`);
  }

  getCountryStats() {
    return axios.get(`/country-stats`);
  }

  getCountryItems(slug) {
    return axios.get(`/country/${slug}/items`);
  }

  getHomePagePhotos() {
    return axios.get(`/homepage/photos`);
  }

  getMapData() {
    return axios.get(`/map`);
  }
}

export default new PublicService();
