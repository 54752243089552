<template>
  <v-container fluid>
    <Snackbar :alert="alert" :msg="msg" :color="color"></Snackbar>
    <h1>Gallery management</h1>
    <v-row>
      <v-col class="text-right">
        <v-btn class="my-3 ml-auto success" @click="openAddEditModal()">
          <v-icon left> mdi-image-plus </v-icon>
          Add
        </v-btn>
      </v-col>
    </v-row>

    <!-- save button -->
    <transition name="fade">
      <v-row v-if="galleriesChanged">
        <v-col class="text-left">
          <v-btn class="my-3 ml-auto accent" @click="saveGalleryOrder()">
            <v-icon left> mdi-content-save </v-icon>
            Save gallery order
          </v-btn>
        </v-col>
      </v-row>
    </transition>

    <v-data-table
      dense
      class="elevation-2 pt-2"
      :items="galleries"
      :search="search"
      :headers="headers"
      :footer-props="{ 'items-per-page-options': [100, 200, 300] }"
      :items-per-page="200"
    >
      <template v-slot:top>
        <v-text-field v-model="search" label="Search for any value" class="mx-4"></v-text-field>
      </template>

      <template v-slot:body="{ items }">
        <draggable :list="items" tag="tbody" @change="reorderedGallery(items)">
          <tr v-for="(item, index) in items" :key="index" class="overflow-y-auto" style="max-height: 300px">
            <td style="width: 5%">
              <v-icon small class="page__grab-icon"> mdi-arrow-all </v-icon>
            </td>
            <td style="width: 10%">{{ item.gallery }}</td>
            <td style="width: 20%">{{ item.country_name }}</td>
            <td style="width: 35%">{{ item.description }}</td>
            <td style="width: 10%">{{ index + 1 }}</td>
            <td style="width: 10%">{{ item.photo_count }}</td>
            <td style="width: 10%">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="secondary" class="pointer mr-2" v-bind="attrs" v-on="on" @click.stop="openGalleryDetails(item)"
                    >mdi-image-multiple-outline</v-icon
                  >
                </template>
                <span>Manage pictures</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" class="pointer mr-2" v-bind="attrs" v-on="on" @click.stop="editDialog(item)">mdi-pencil</v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="error" class="pointer mr-2" v-bind="attrs" v-on="on" @click.stop="deleteGallery(item)">mdi-trash-can</v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table>

    <!-- ADD DIALOG -->
    <v-dialog v-model="addModal" max-width="60%" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>{{ editing ? 'Edit' : 'Add' }} gallery</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeAddEditModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form class="pb-5">
          <v-container fill-height fluid>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="6">
                <v-row align="center" justify="center" class="my-5">
                  <v-col cols="12" sm="10">
                    <h4 class="mb-5">Gallery details</h4>
                    <v-text-field autofocus v-model="gallery.gallery" label="Gallery name"></v-text-field>
                    <v-text-field dense v-model="slug" label="Slug" class="ma-0 pa-0" disabled style="width: 50%; font-size: 0.8rem"></v-text-field>
                    <v-autocomplete
                      clearable
                      :items="countries"
                      item-text="name"
                      item-value="id_country"
                      v-model="gallery.id_country"
                      label="Country"
                    ></v-autocomplete>
                    <v-textarea row-height="5" auto-grow v-model="gallery.description" label="Description"></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn v-if="!editing" class="success mx-2" :loading="loading" tabindex="90" @click="add()"> Add </v-btn>
                    <v-btn v-if="editing" class="success mx-2" :loading="loading" tabindex="90" @click="edit()"> Edit </v-btn>
                    <v-btn class="secondary" tabindex="99" @click="closeAddEditModal()">Cancel</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- DELETE DIALOG -->
    <v-dialog v-model="deleteModal" max-width="60%" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar color="error" dark>
          <v-toolbar-title>Delete gallery</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="deleteModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form class="pb-5">
          <v-container fill-height fluid>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="6">
                <v-row align="center" justify="center" class="my-5">
                  <v-col v-if="selectedGallery">
                    <p class="mb-5 text-center">Do you really want to delete gallery "{{ selectedGallery.gallery }}"?</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn app class="error mx-2" :loading="loading" tabindex="90" @click="deleteConfirm()"> Delete </v-btn>
                    <v-btn app class="secondary" tabindex="99" @click="deleteModal = false">Cancel</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { loading } from '../../mixins/index';
import { formatHeaders } from '../../helpers/data-tables';
import { nullObject } from '../../helpers/functions';
import AdminService from './admin.service';
import draggable from 'vuedraggable';
import PublicService from './../public.service';

export default {
  name: 'AdminGalleries',
  mixins: [loading.default],
  components: { draggable },
  data: () => ({
    galleries: [],
    galleriesChanged: false,
    countries: [],
    search: null,
    selectedGallery: null,
    addModal: false,
    deleteModal: false,
    editing: false,
    gallery: {
      id_country: null,
      gallery: '',
      description: '',
      slug: ''
    },
    sortedGallery: []
  }),
  methods: {
    reorderedGallery(array) {
      this.sortedGallery = [];
      array.forEach((g, i) => {
        this.sortedGallery.push({
          gallery_order: i + 1,
          id_gallery: g.id_gallery
        });
      });
      this.galleriesChanged = true;
    },
    openGalleryDetails(item) {
      this.$router.push({ name: 'admin-galleries-photo', params: { gallery: item } });
    },
    saveGalleryOrder() {
      AdminService.updateGalleryOrder(this.sortedGallery)
        .then(() => {
          this.setAlertColor('primary');
          this.setMessage('Gallery order saved');
          this.triggerAlert();
        })
        .catch(error => {
          console.log(error);
          this.setAlertColor('error');
          this.setMessage(error?.response?.data?.error?.message || error?.response?.data?.error || 'Something went wrong');
          this.triggerAlert();
        });
      this.galleriesChanged = false;
    },
    deleteConfirm() {
      AdminService.deleteGallery(this.selectedGallery.id_gallery)
        .then(() => {
          this.getGalleries();
          this.setAlertColor('primary');
          this.setMessage('Gallery removed');
          this.triggerAlert();
        })
        .catch(error => {
          console.log(error);
          this.setAlertColor('error');
          this.setMessage(error?.response?.data?.error?.message || error?.response?.data?.error || 'Something went wrong');
          this.triggerAlert();
        });
      this.deleteModal = false;
    },
    getGalleries() {
      PublicService.getGalleries().then(
        result => {
          this.galleries = result.data;
        },
        error => {
          console.log(error);
        }
      );
    },
    getCountries() {
      AdminService.getCountries().then(
        result => {
          this.countries = result.data.filter(c => c.visited == '1');
        },
        error => {
          console.log(error);
        }
      );
    },
    openAddEditModal() {
      this.addModal = true;
      this.gallery = {};
      this.editing = false;
    },
    deleteGallery(item) {
      this.deleteModal = true;
      this.selectedGallery = item;
    },
    editDialog(item) {
      this.editing = true;
      this.addModal = true;
      this.gallery = { ...item };
    },
    closeAddEditModal() {
      this.addModal = false;
      this.editing = false;
      this.gallery = {};
    },
    add() {
      this.triggerLoading();

      // payload check
      if (Object.values(this.gallery).some(val => val === null)) {
        this.setAlertColor('error');
        this.setMessage('All fields are required');
        this.disableLoading();
        this.triggerAlert();
        return;
      }

      this.gallery.slug = this.slug;

      AdminService.addGallery(this.gallery)
        .then(() => {
          this.disableLoading();
          this.getGalleries();
          nullObject(this.gallery);
          this.addModal = false;
          this.editing = false;
          this.setAlertColor('primary');
          this.setMessage('Gallery added');
          this.triggerAlert();
        })
        .catch(error => {
          console.log(error);
          this.setAlertColor('error');
          this.setMessage(error?.response?.data?.error?.message || error?.response?.data?.error || 'Something went wrong');
          this.disableLoading();
          this.triggerAlert();
        });
    },
    edit() {
      this.triggerLoading();

      const gallery = (({ id_gallery, gallery, description, id_country }) => ({ id_gallery, gallery, description, id_country }))(this.gallery);

      // payload check
      if (Object.values(gallery).some(val => val === null)) {
        this.setAlertColor('error');
        this.setMessage('All fields are required');
        this.disableLoading();
        this.triggerAlert();
        return;
      }

      gallery.slug = this.slug;

      AdminService.editGallery({ id: gallery.id_gallery, payload: gallery })
        .then(() => {
          this.disableLoading();
          this.getGalleries();
          nullObject(this.gallery);
          this.addModal = false;
          this.setAlertColor('primary');
          this.setMessage('Gallery modified');
          this.triggerAlert();
        })
        .catch(error => {
          console.log(error);
          this.setAlertColor('error');
          this.setMessage(error?.response?.data?.error?.message || error?.response?.data?.error || 'Something went wrong');
          this.disableLoading();
          this.triggerAlert();
        });
    }
  },
  created() {
    this.getCountries();
    this.getGalleries();
  },
  computed: {
    headers() {
      const addtlItems = [{ value: 'Action', text: 'Action', sortable: false, align: 'center' }];
      const removeItems = ['id_country', 'cover_image', 'slug'];
      return formatHeaders(this.galleries, addtlItems, removeItems);
    },
    slug() {
      if (!this.gallery?.gallery) return '';
      return (
        this.gallery.gallery
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          // eslint-disable-next-line no-useless-escape
          .replace(/[&\/\\#,;+()$\^~%.§=`'"*?<>ŧ↓←→Đđ!\|[\]@łþ¶°€{}]/gi, '')
          .replace(/\s+/gi, '-')
          .toLowerCase()
      );
    }
  }
};
</script>

<style lang="css" scoped></style>
