<template>
  <v-container fluid>
    <Snackbar :alert="alert" :msg="msg" :color="color"></Snackbar>
    <h1>Dashboard</h1>
    <v-container fluid fill-height>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="6">
          <v-card min-height="300">
            <v-card-title class="grey lighten-2">Failed logins</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-data-table dense :headers="headersFailed" :items="failedLogins" :search="searchFailed"> </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card min-height="300">
            <v-card-title class="grey lighten-2">Google analytics</v-card-title>
            <v-divider></v-divider>
            <v-card-text> In progress </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-card min-height="300">
            <v-card-title class="grey lighten-2">Quick actions</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="text-center">
                <v-col>
                  <v-btn class="my-3 ml-auto accent" :to="{ name: 'admin-burgers' }">
                    <v-icon left> mdi-hamburger-plus </v-icon>
                    Add burger
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <v-btn class="my-3 ml-auto accent" :to="{ name: 'admin-galleries' }">
                    <v-icon left> mdi-image-plus </v-icon>
                    Add gallery
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="text-center">
                <v-col>
                  <v-btn class="my-3 ml-auto accent" :to="{ name: 'admin-countries' }">
                    <v-icon left> mdi-mapbox </v-icon>
                    Manage countries
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <v-btn class="my-3 ml-auto accent" :to="{ name: 'admin-quotes' }">
                    <v-icon left> mdi-text </v-icon>
                    Manage quotes
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card min-height="300">
            <v-card-title class="grey lighten-2">Tech overview</v-card-title>
            <v-divider></v-divider>
            <v-card-text> TBD </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import AdminService from './admin.service';
import { formatHeaders } from '../../helpers/data-tables';

export default {
  name: 'AdminDashboard',
  components: {},
  data: () => ({
    failedLogins: [],
    searchFailed: null
  }),
  methods: {
    getFailedLogins() {
      AdminService.failedLogins().then(
        result => {
          this.failedLogins = result.data;
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  created() {
    this.getFailedLogins();
  },
  computed: {
    headersFailed() {
      return formatHeaders(this.failedLogins);
    }
  }
};
</script>

<style lang="css" scoped></style>
