import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import svgJs from './plugins/vueSvgPlugin';
import { router } from './router/index';
import { store } from './store/index';

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(svgJs);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
