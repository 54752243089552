<template>
  <v-container fluid class="main">
    <Header></Header>
    <v-container class="gallery mt-4" fluid>
      <v-row>
        <!-- eslint-disable -->
        <transition-group name="view" appear tag="div" class="transition_box" justify="center" align="center" :style="{ '--total': galleries.length }">
          <v-col
            class="gallery__cover view-item"
            cols="12"
            sm="6"
            md="4"
            lg="3"
            v-for="(gallery, i) in galleries"
            :style="{ '--i': i }"
            :key="gallery.id_gallery"
            v-if="showItems && i < limit"
          >
            <template>
              <div class="gallery__cover_item pointer" :key="gallery.id_gallery" @click="openGalleryDetails(gallery.slug)">
                <img v-if="gallery.thumb_img_path" class="gallery__cover_image" :src="gallery.thumb_img_path" alt="" />
                <div class="gallery__cover_title text-center">
                  <span class="label">{{ gallery.gallery }}</span>
                </div>
              </div>
            </template>
          </v-col>
        </transition-group>
        <!-- eslint-enable -->
      </v-row>
      <ToTop></ToTop>
    </v-container>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from './shared/Header.vue';
import Footer from './shared/Footer.vue';
import ToTop from './shared/ToTop.vue';
import PublicService from './public.service';

export default {
  name: 'Galerie',
  components: {
    Header,
    Footer,
    ToTop
  },
  data: () => ({
    showItems: false,
    serverHost: process.env.VUE_APP_API_BASE_URL,
    galleries: []
  }),
  methods: {
    openGalleryDetails(slug) {
      this.$router.push({ name: 'galerie-detail', params: { slug } });
    },
    getGalleries() {
      PublicService.getGalleries().then(
        result => {
          this.galleries = result.data;
          this.galleries.forEach(g => {
            if (g.cover_image) {
              g.full_img_path = this.serverHost + g.cover_image.replace('/uploads', '');
              g.thumb_img_path = this.serverHost + g.cover_image.replace('/uploads', '').replace(g.slug, `${g.slug}/thumbnails`);
            }
          });
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  created() {
    this.getGalleries();
    this.$nextTick(() => {
      this.showItems = true;
    });
  },
  computed: {
    limit() {
      return this.galleries.length;
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  padding: 0;
}
.gallery__cover {
  margin: 0;
  padding: 0;
}
.gallery__cover_item {
  position: relative;
  transition: 0.2s;
  overflow: hidden;
  height: 20vw;
}

@media only screen and (max-width: 2000px) {
  .gallery__cover_item {
    height: 18vw;
  }
}
@media only screen and (max-width: 1263px) {
  .gallery__cover_item {
    height: 30vw;
  }
}
@media only screen and (max-width: 960px) {
  .gallery__cover_item {
    height: 40vw;
  }
}
@media only screen and (max-width: 599px) {
  .gallery__cover_item {
    height: 70vw;
  }
}
.gallery__cover_image {
  width: 100%;
  display: block;
}
.gallery__cover_title {
  color: #000000;
  background: #ccc;
  width: 50%;
  margin: 0 auto;
  border-radius: 5px;
  padding: 0.3rem;
  position: absolute;
  inset: 0;
  top: auto;
  opacity: 0.7;
  bottom: 1.5rem;
}

.gallery__cover_title .label {
  opacity: 1;
  font-weight: 400;
}
</style>
