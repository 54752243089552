<template>
  <v-btn v-scroll="onScroll" v-show="fab" fab dark fixed bottom right class="toTop" @click="toTop">
    <v-icon>mdi-arrow-up</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ToTop',
  components: {},
  data: () => ({
    fab: false
  }),
  methods: {
    onScroll(e) {
      if (typeof window === 'undefined') return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    }
  }
};
</script>

<style lang="css" scoped>
.toTop {
  z-index: 20;
  opacity: 0.4;
}
</style>
