<template>
  <div id="worldMap" class="svg-container"></div>
</template>
<script>
import * as d3 from 'd3';
import * as topojson from 'topojson';

export default {
  name: 'WorldMapD3',
  data: () => ({
    serverHost: process.env.VUE_APP_API_BASE_URL,
    mapAttr: {
      viewBoxWidth: 1200,
      viewBoxHeight: 700
    },
    mapData: {}
  }),
  props: {
    mapOptions: Object
  },
  methods: {},
  computed: {},
  async mounted() {
    const vueInstance = this;
    const svg = d3
      .select('#worldMap')
      .append('svg')
      .attr('viewBox', '0 0 ' + this.mapAttr.viewBoxWidth + ' ' + this.mapAttr.viewBoxHeight);

    const projection = d3
      .geoMercator()
      .scale(150)
      .translate([this.mapAttr.viewBoxWidth / 2, this.mapAttr.viewBoxHeight / 1.4]);
    const path = d3.geoPath(projection);

    const g = svg.append('g');

    // const countryList = this.mapOptions.countryList;
    // const visitedCountries = this.mapOptions.selectedCountries;

    // d3.json(worldMapData).then(data => {
    d3.json(`${this.serverHost}/worldMapD3.json`).then(data => {
      const countries = topojson.feature(data, data.objects.countries);
      // prettier-ignore
      g.selectAll('path').data(countries.features)
      .enter().append('path')
        .attr('class', 'country')
        .attr('d', path)
        .filter(d => vueInstance.mapOptions.selectedCountries.includes(d.properties.code))
        .classed("selected pointer", true)
    });

    g.on('click', function (e) {
      const clickedCountryCode = e.srcElement['__data__'].properties.code;
      if (vueInstance.mapOptions.selectedCountries.includes(clickedCountryCode)) {
        const slug = vueInstance.mapOptions.countryList.find(c => c.country_code === clickedCountryCode).country_slug;
        vueInstance.$router.push({ name: 'country', params: { slug }, replace: false });
      }
    });
    g.on('mouseover', function (e) {
      const countryName = e.srcElement['__data__'].properties.name;
      d3.select(this).select('title').remove();
      d3.select(this).append('title').text(e.srcElement['__data__'].properties.name);
      d3.select(this).attr('data-tooltip', countryName);
    });
  }
};
</script>

<style>
svg {
  margin: 0 auto;
  display: block;
}
.country {
  fill: rgb(200, 238, 255);
  transition: 20ms ease-in-out;
  stroke: #999;
}
.selected,
.selected:hover {
  fill: rgb(0, 100, 145);
}
.country:hover {
  fill: rgb(108, 150, 255);
  transition: 20ms ease-in-out;
}
</style>
