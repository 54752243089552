<template>
  <v-container fluid>
    <v-app-bar app dark clipped-left>
      <v-app-bar-nav-icon class="grey--text" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <router-link :to="{ name: 'admin-home' }">
        <v-img :lazy-src="logo" max-height="100" max-width="150" align="center" class="ml-5" :src="logo"></v-img>
      </router-link>
      <v-spacer></v-spacer>
      <v-btn href="#" class="font-weight-regular" style="text-transform: none; letter-spacing: 0" @click="logout()">
        <v-icon>mdi-logout</v-icon>Sign out ({{ this.$store.state.auth.user.username }})
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer app light clipped v-model="drawer">
      <!-- ADMIN MENU -->
      <SidebarMenu :menuItems="adminMenu" title="Admin"></SidebarMenu>
      <SidebarMenu :menuItems="generalMenu" title="General"></SidebarMenu>
      <template v-slot:append>
        <div class="pa-2">
          <router-link :to="{ name: 'home' }" target="_blank" style="text-decoration: none">
            <v-btn small block elevation="0" style="background-color: #ffffff !important" class="text-lowercase">
              Preview pages <v-icon right x-small>mdi-open-in-new</v-icon>
            </v-btn>
          </router-link>
        </div>
      </template>
    </v-navigation-drawer>
    <v-main relative>
      <router-view></router-view>
    </v-main>
  </v-container>
</template>

<script>
import SidebarMenu from './menu/Sidebar.vue';

export default {
  name: 'Home',
  components: {
    SidebarMenu
  },
  data: () => ({
    drawer: true,
    logo: './../../imgs/zp_main_transparent.png',
    adminMenu: [
      {
        id: 1000,
        name: 'Dashboard',
        icon: 'mdi-billboard',
        link: 'admin-home'
      },
      {
        id: 2000,
        name: 'Homepage Photos',
        icon: 'mdi-image-multiple',
        link: 'admin-homepage-photos'
      },
      {
        id: 3000,
        name: 'Galeries',
        icon: 'mdi-image-multiple',
        link: 'admin-galleries'
      },
      {
        id: 4000,
        name: 'Burger Tour',
        icon: 'mdi-hamburger',
        link: 'admin-burgers'
      },
      {
        id: 5000,
        name: 'Countries',
        icon: 'mdi-mapbox',
        link: 'admin-countries'
      },
      {
        id: 6000,
        name: 'Quotes',
        icon: 'mdi-text',
        link: 'admin-quotes'
      }
    ],
    generalMenu: [
      {
        id: 9001,
        name: 'Settings',
        icon: 'mdi-cog',
        link: 'admin-settings'
      }
    ]
  }),
  methods: {
    logout() {
      this.$router.push({ name: 'login' }); //redirect to login
      this.$store.dispatch('auth/logout'); //dispatch logout (destroy all session data)
    }
  }
};
</script>
