<template>
  <v-container fluid>
    <Snackbar :alert="alert" :msg="msg" :color="color"></Snackbar>
    <h1>Burgers</h1>
    <v-row>
      <v-col class="text-right">
        <v-btn class="my-3 ml-auto success" @click="openAddEditModal()">
          <v-icon left> mdi-hamburger-plus </v-icon>
          Add
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      dense
      class="elevation-2 pt-2"
      :items="burgers"
      :search="search"
      :headers="headers"
      :footer-props="{ 'items-per-page-options': [10, 20, 30] }"
      :items-per-page="10"
    >
      <template v-slot:top>
        <v-text-field v-model="search" label="Search for any value" class="mx-4"></v-text-field>
      </template>

      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(item, index) in items" :key="item.id_quote" class="overflow-y-auto" style="max-height: 300px">
            <td>{{ index + 1 }}</td>
            <td>{{ item.place }}</td>
            <td>{{ item.city }}</td>
            <td>{{ item.country_name }}</td>
            <td>
              <v-img :src="item.thumb_img_path" style="width: 5vw; max-height: 5vh" class="pointer" @click="selectedImage = item.full_img_path"></v-img>
            </td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" class="pointer mr-2" v-bind="attrs" v-on="on" @click.stop="editBurgerDialog(item)">mdi-pencil</v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="error" class="pointer mr-2" v-bind="attrs" v-on="on" @click.stop="deleteBurger(item)">mdi-trash-can</v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <!-- ADD BURGER DIALOG -->
    <v-dialog v-model="addBurgerModal" max-width="60%" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>{{ editing ? 'Edit' : 'Add' }} burger</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeAddEditModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form class="pb-5">
          <v-container fill-height fluid>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="6">
                <v-row align="center" justify="center" class="my-5">
                  <v-col cols="12" sm="10">
                    <h4 class="mb-5">Burger details</h4>
                    <v-file-input
                      prepend-icon="mdi-hamburger"
                      show-size
                      dense
                      v-model="burger.picture_path"
                      label="Burger picture"
                      truncate-length="15"
                      v-if="removed_image || !editing"
                    ></v-file-input>
                    <div class="mb-4 mx-auto d-block" justify="center" align="center" v-if="editing && !removed_image">
                      <small>Current image: </small>
                      <v-img :src="burger.thumb_img_path" style="width: 5vw"></v-img>
                      <v-btn @click="deleteBurgerImage()" x-small class="accent"><v-icon x-small>mdi-trash-can</v-icon> Delete image </v-btn>
                    </div>
                    <v-text-field dense v-model="burger.place" label="Name of the restaurant"></v-text-field>
                    <v-text-field dense v-model="slug" label="Slug" class="ma-0 pa-0" disabled style="width: 50%; font-size: 0.8rem"></v-text-field>
                    <v-autocomplete
                      dense
                      clearable
                      :items="countries"
                      item-text="name"
                      item-value="id_country"
                      v-model="burger.id_country"
                      label="Country"
                    ></v-autocomplete>
                    <v-text-field dense v-model="burger.city" label="City"></v-text-field>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="burger.date_tasted"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="burger.date_tasted"
                          label="Tasting day"
                          prepend-icon="mdi-calendar"
                          readonly
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="burger.date_tasted" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
                        <v-btn text color="primary" @click="$refs.menu.save(burger.date_tasted)"> OK </v-btn>
                      </v-date-picker>
                    </v-menu>
                    <v-textarea row-height="5" auto-grow dense v-model="burger.description" label="Description"></v-textarea>
                    <v-divider dense class="my-3"></v-divider>
                    <h4>Rating</h4>
                  </v-col>
                </v-row>
                <v-row dense align="center" justify="center">
                  <v-col cols="4" sm="3" lg="3" xl="2"> Houska: </v-col>
                  <v-col cols="12" sm="6">
                    <v-rating
                      dense
                      v-model="burger.houska"
                      color="yellow darken-3"
                      background-color="#eee"
                      empty-icon="$ratingFull"
                      half-increments
                      hover
                    ></v-rating>
                  </v-col>
                </v-row>
                <v-row dense align="center" justify="center">
                  <v-col cols="4" sm="3" lg="3" xl="2"> Maso: </v-col>
                  <v-col cols="12" sm="6">
                    <v-rating
                      dense
                      v-model="burger.maso"
                      color="yellow darken-3"
                      background-color="#eee"
                      empty-icon="$ratingFull"
                      half-increments
                      hover
                    ></v-rating>
                  </v-col>
                </v-row>
                <v-row dense align="center" justify="center">
                  <v-col cols="4" sm="3" lg="3" xl="2"> Obsluha: </v-col>
                  <v-col cols="12" sm="6">
                    <v-rating
                      dense
                      v-model="burger.obsluha"
                      color="yellow darken-3"
                      background-color="#eee"
                      empty-icon="$ratingFull"
                      half-increments
                      hover
                    ></v-rating>
                  </v-col>
                </v-row>
                <v-row dense align="center" justify="center">
                  <v-col cols="4" sm="3" lg="3" xl="2"> Prostředí: </v-col>
                  <v-col cols="12" sm="6">
                    <v-rating
                      dense
                      v-model="burger.prostredi"
                      color="yellow darken-3"
                      background-color="#eee"
                      empty-icon="$ratingFull"
                      half-increments
                      hover
                    ></v-rating>
                  </v-col>
                </v-row>
                <v-row dense align="center" justify="center">
                  <v-col cols="4" sm="3" lg="3" xl="2"> Hranolky: </v-col>
                  <v-col cols="12" sm="6">
                    <v-rating
                      dense
                      v-model="burger.hranolky"
                      color="yellow darken-3"
                      background-color="#eee"
                      empty-icon="$ratingFull"
                      half-increments
                      hover
                    ></v-rating>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn v-if="!editing" class="success mx-2" :loading="loading" tabindex="90" @click="addBurger()"> Add </v-btn>
                    <v-btn v-if="editing" class="success mx-2" :loading="loading" tabindex="90" @click="editBurger()"> Edit </v-btn>
                    <v-btn class="secondary" tabindex="99" @click="closeAddEditModal()">Cancel</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- DELETE BURGER DIALOG -->
    <v-dialog v-model="deleteBurgerModal" max-width="60%" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar color="error" dark>
          <v-toolbar-title>Delete burger</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="deleteBurgerModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form class="pb-5">
          <v-container fill-height fluid>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="6">
                <v-row align="center" justify="center" class="my-5">
                  <v-col v-if="selectedBurger">
                    <p class="mb-5 text-center">Do you really want to delete burger "{{ selectedBurger.place }}"?</p>
                    <img :src="selectedBurger.full_img_path" style="width: 15vw" class="mx-auto d-flex" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn app class="error mx-2" :loading="loading" tabindex="90" @click="deleteBurgerConfirmed()"> Delete </v-btn>
                    <v-btn app class="secondary" tabindex="99" @click="deleteBurgerModal = false">Cancel</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- show burger image -->
    <v-overlay v-if="selectedImage" opacity="0.9" @click="selectedImage = null">
      <v-img contain :src="selectedImage ? selectedImage : ''" class="burger_image_preview"></v-img>
    </v-overlay>
  </v-container>
</template>

<script>
import { loading, keyboardHandler } from '../../mixins/index';
import { formatHeaders } from '../../helpers/data-tables';
import { nullObject } from '../../helpers/functions';
import AdminService from './admin.service';
import PublicService from './../public.service';

export default {
  name: 'AdminBurgers',
  mixins: [loading.default, keyboardHandler.default],
  components: {},
  data: () => ({
    burgers: [],
    serverHost: process.env.VUE_APP_API_BASE_URL,
    selectedBurger: null,
    selectedImage: null,
    countries: [],
    search: null,
    menu: false,
    addBurgerModal: false,
    deleteBurgerModal: false,
    editing: false,
    removed_image: false,
    burger: {
      picture_path: null,
      place: null,
      id_country: null,
      city: null,
      date_tasted: null,
      description: null,
      houska: null,
      maso: null,
      obsluha: null,
      prostredi: null,
      hranolky: null,
      slug: null
    },
    starRatingOptions: ['0', '1', '1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5']
  }),
  methods: {
    getBurgers() {
      PublicService.getBurgers().then(
        result => {
          this.burgers = result.data;
          this.burgers.forEach(b => {
            b.full_img_path = this.serverHost + b.picture_path.replace('/uploads', '') + '/' + b.picture_name;
            b.thumb_img_path = this.serverHost + b.picture_path.replace('/uploads', '') + '/thumbnails/' + b.picture_name;
          });
        },
        error => {
          console.log(error);
        }
      );
    },
    openAddEditModal() {
      this.addBurgerModal = true;
      this.burger = {};
      this.removed_image = false;
      this.editing = false;
    },
    closeAddEditModal() {
      this.addBurgerModal = false;
      this.editing = false;
      this.burger = {};
    },
    addBurger() {
      this.triggerLoading();
      const formData = new FormData();

      // payload check
      if (Object.values(this.burger).some(val => val === null)) {
        this.setAlertColor('error');
        this.setMessage('All fields are required');
        this.disableLoading();
        this.triggerAlert();
        return;
      }

      // add slug
      this.burger.slug = this.slug;

      formData.append('file', this.burger.picture_path);
      formData.append('burger', JSON.stringify(this.burger));

      AdminService.addBurger(formData)
        .then(() => {
          this.disableLoading();
          this.getBurgers();
          nullObject(this.burger);
          this.addBurgerModal = false;
          this.setAlertColor('primary');
          this.setMessage('Burger added');
          this.triggerAlert();
        })
        .catch(error => {
          this.setAlertColor('error');
          this.setMessage(error?.response?.data?.error?.message || error?.response?.data?.error || 'Something went wrong');
          this.disableLoading();
          this.triggerAlert();
        });
    },
    editBurger() {
      this.triggerLoading();
      const formData = new FormData();

      // payload check
      if (Object.values(this.burger).some(val => val === null)) {
        this.setAlertColor('error');
        this.setMessage('All fields are required');
        this.disableLoading();
        this.triggerAlert();
        return;
      }

      // add slug
      this.burger.slug = this.slug;

      formData.append('file', this.burger.picture_path);
      formData.append('burger', JSON.stringify(this.burger));

      AdminService.editBurger(this.burger.id_burger, formData)
        .then(() => {
          this.disableLoading();
          nullObject(this.burger);
          this.addBurgerModal = false;
          this.setAlertColor('primary');
          this.setMessage('Burger updated');
          this.triggerAlert();
          this.getBurgers();
        })
        .catch(error => {
          this.setAlertColor('error');
          this.setMessage(error?.response?.data?.error?.message || error?.response?.data?.error || 'Something went wrong');
          this.disableLoading();
          this.triggerAlert();
        });
    },
    getCountries() {
      AdminService.getCountries().then(
        result => {
          this.countries = result.data.filter(c => c.visited == '1');
        },
        error => {
          console.log(error);
        }
      );
    },
    editBurgerDialog(item) {
      this.editing = true;
      this.addBurgerModal = true;
      this.burger = { ...item };
      this.removed_image = false;
    },
    deleteBurger(item) {
      this.deleteBurgerModal = true;
      this.selectedBurger = item;
    },
    deleteBurgerImage() {
      this.removed_image = true;
      this.burger.picture_path = null;
    },
    deleteBurgerConfirmed() {
      this.triggerLoading();

      if (!this.selectedBurger) {
        this.setAlertColor('error');
        this.setMessage('No burger selected');
        this.disableLoading();
        this.triggerAlert();
        return;
      }

      AdminService.deleteBurger(this.selectedBurger.id_burger)
        .then(() => {
          this.disableLoading();
          this.deleteBurgerModal = false;
          this.getBurgers();
          this.selectedBurger = {};
          this.setAlertColor('success');
          this.setMessage('Burger removed');
          this.triggerAlert();
        })
        .catch(error => {
          this.setAlertColor('error');
          this.setMessage(error?.response?.data?.error?.message || error?.response?.data?.error || 'Something went wrong');
          this.disableLoading();
          this.triggerAlert();
        });
    }
  },
  created() {
    this.getCountries();
    this.getBurgers();
  },
  computed: {
    headers() {
      const addtlItems = [
        { value: 'Preview', text: 'Preview', sortable: false, align: 'center' },
        { value: 'Action', text: 'Action', sortable: false, align: 'center' }
      ];
      const removeItems = [
        'picture_path',
        'date_tasted',
        'picture_name',
        'houska',
        'maso',
        'obsluha',
        'prostredi',
        'hranolky',
        'description',
        'id_country',
        'full_img_path',
        'thumb_img_path',
        'slug'
      ];
      return formatHeaders(this.burgers, addtlItems, removeItems);
    },
    slug() {
      if (!this.burger?.place) return '';
      return (
        this.burger.place
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          // eslint-disable-next-line no-useless-escape
          .replace(/[&\/\\#,;+()$\^~%.§=`'"*?<>ŧ↓←→Đđ!\|[\]@łþ¶°€{}]/gi, '')
          .replace(/\s+/gi, '-')
          .toLowerCase()
      );
    }
  }
};
</script>

<style lang="css" scoped>
.burger_image_preview {
  width: 60vw;
  height: 60vh;
}
</style>
