<template>
  <v-snackbar :value="alert" elevation="3" transition="slide-x-transition" timeout="5000" top right :color="color">
    {{ msg }}
  </v-snackbar>
</template>

<script>
export default {
  name: 'AlertSnackbar',
  props: {
    alert: { type: Boolean },
    msg: { type: String },
    color: { type: String }
  }
};
</script>
